/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Wednesday 22 June 2022 20:33:46
*/

import { lazy } from 'react';
import Loadable from 'app/components/Loadable/Loadable';

//***************************************************************************/
//***************************************************************************/
//** data studio routes components */

const DataStudio = Loadable(lazy(() => import('./data-studio/DataStudio')));

//***************************************************************************/
//***************************************************************************/

const dataStudioRoutes = [
  //** data-studio routes  */
  {
    path: '/data-studio/',
    element: <DataStudio />,
  },
];

export default dataStudioRoutes;

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Wednesday 22 June 2022 20:34:00
*/
