/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Thursday 13 October 2022 03:41:35
*/

import http from '../http';

//** get all participant http request */
export const GetAllParticipantsProfile = async () => {
  try {
    const res = await http.get('/participants');
    return res.data.data;
  } catch (err) {}
};

//** get participant by id http request */
export const GetParticipantProfileById = async (id) => {
  try {
    const res = await http.get(`/participants/${id}`);
    return res.data.data;
  } catch (err) {
    return err;
  }
};

//** create participant http request */
export const CreateParticipantProfile = async (data) => {
  try {
    const res = await http.post('/participants/', data);
    return res.data.data;
  } catch (err) {
    return err;
  }
};

//** update participant by id http request */
export const UpdateParticipantProfileById = async (id, data) => {
  const res = await http.patch(`/participants/${id}`, data);
  return res.data.data;
};

//** delete participant http request */
export const DeleteParticipantProfileById = async (id) => {
  const res = await http.delete(`/participants/${id}`);
  return res.data;
};

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Wednesday 15 March 2023 12:56:44
*/
