/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Saturday 01 October 2022 23:13:15
*/

import { createSlice } from '@reduxjs/toolkit';

import {
  getAllSmsUsageHistory,
  createSmsUsageHistory,
  getAllEmailUsageHistory,
  createEmailUsageHistory,
} from '../thunk/smsEmailUsageHistory.Thunk';

const initialState = {
  smsUsageHistory: [],
  emailUsageHistory: [],
};

export const smsEmailUsageHistorySlice = createSlice({
  name: 'smsEmailUsageHistory',
  initialState,
  extraReducers: (builder) => {
    //** sms usage history slice */
    builder.addCase(getAllSmsUsageHistory.fulfilled, (state, { payload }) => {
      state.smsUsageHistory = payload;
    });

    builder.addCase(createSmsUsageHistory.fulfilled, (state, { payload }) => {
      const { smsUsageHistoryState } = payload;
      state.smsUsageHistory = smsUsageHistoryState;
    });

    //** email usage history slice */
    builder.addCase(getAllEmailUsageHistory.fulfilled, (state, { payload }) => {
      state.emailUsageHistory = payload;
    });

    builder.addCase(createEmailUsageHistory.fulfilled, (state, { payload }) => {
      const { emailUsageHistoryState } = payload;
      state.emailUsageHistory = emailUsageHistoryState;
    });
  },
});

export default smsEmailUsageHistorySlice.reducer;

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Monday 22 May 2023 18:02:02
*/
