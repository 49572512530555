/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Wednesday 13 April 2022 15:57:45
*/

import { createSlice } from '@reduxjs/toolkit';

import {
  getAllAppConfig,
  createAppConfig,
  updateAppConfigById,
  deleteAppConfigById,
} from '../thunk/appConfig.Thunk';

const initialState = {
  appConfig: [],
};

export const appConfigSlice = createSlice({
  name: 'config',
  initialState,
  //** app config slice */
  extraReducers: (builder) => {
    builder.addCase(getAllAppConfig.fulfilled, (state, { payload }) => {
      state.appConfig = payload;
    });

    builder.addCase(createAppConfig.fulfilled, (state, { payload }) => {
      const { appConfigState } = payload;
      state.appConfig = appConfigState;
    });

    builder.addCase(updateAppConfigById.fulfilled, (state, { payload }) => {
      const { appConfigState } = payload;
      state.appConfig = appConfigState;
    });

    builder.addCase(deleteAppConfigById.fulfilled, (state, { payload }) => {
      const { appConfigState } = payload;
      state.appConfig = appConfigState;
    });
  },
});

export default appConfigSlice.reducer;

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Tuesday 14 March 2023 17:19:49
*/
