/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Monday 18 July 2022 02:34:16
*/

import { Navigate, useLocation } from 'react-router-dom';

const Redirect = () => {
  let location = useLocation();

  // Get redirect location or provide fallback
  const from = location.state?.from || '/dashboard';

  // in auth callback logic, once authenticated navigate (redirect) back
  // to the route originally being accessed.
  return <Navigate to={from} />;
};

export default Redirect;

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Tuesday 27 December 2022 21:42:49
*/
