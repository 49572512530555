/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Sunday 23 April 2023 16:45:15
*/

import { createSlice } from '@reduxjs/toolkit';

import {
  getAllResearchResources,
  createResearchResource,
  updateResearchResourceById,
  deleteResearchResourceById,
} from '../thunk/researchResources.Thunk';

const initialState = {
  researchResources: [],
};

export const researchResourceSlice = createSlice({
  name: 'researchResources',
  initialState,
  extraReducers: (builder) => {
    //** research resources slice */
    builder.addCase(getAllResearchResources.fulfilled, (state, { payload }) => {
      state.researchResources = payload;
    });

    builder.addCase(createResearchResource.fulfilled, (state, { payload }) => {
      const { researchResourcesState } = payload;
      state.researchResources = researchResourcesState;
    });

    builder.addCase(
      updateResearchResourceById.fulfilled,
      (state, { payload }) => {
        const { researchResourcesState } = payload;
        state.researchResources = researchResourcesState;
      }
    );

    builder.addCase(
      deleteResearchResourceById.fulfilled,
      (state, { payload }) => {
        const { researchResourcesState } = payload;
        state.researchResources = researchResourcesState;
      }
    );
  },
});

export default researchResourceSlice.reducer;

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Monday 22 May 2023 17:58:47
*/
