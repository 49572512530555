/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Tuesday 29 November 2022 03:26:05
*/

import http from '../http';

//** get all project sub panels http request */
export const GetAllProjectSubPanels = async () => {
  try {
    const res = await http.get('/project-sub-panels');
    return res.data.data;
  } catch (err) {}
};

//** get project sub panels by id http request */
export const GetProjectSubPanelById = async (id) => {
  try {
    const res = await http.get(`/project-sub-panels/${id}`);
    return res.data.data;
  } catch (err) {
    return err;
  }
};

//** create project sub panels http request */
export const CreateProjectSubPanel = async (data) => {
  try {
    const res = await http.post('/project-sub-panels', data);
    return res.data.data;
  } catch (err) {
    return err;
  }
};

//** update project sub panels by id http request */
export const UpdateProjectSubPanelById = async (id, data) => {
  const res = await http.patch(`/project-sub-panels/${id}`, data);
  return res.data.data;
};

//** delete project sub panels http request */
export const DeleteProjectSubPanelById = async (id) => {
  const res = await http.delete(`/project-sub-panels/${id}`);
  return res.data;
};

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Friday 27 January 2023 03:32:08
*/
