/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Friday 27 January 2023 15:22:37
*/

import { lazy } from 'react';
import Loadable from 'app/components/Loadable/Loadable';

//** project panels routes components*/
//***************************************************************************/
//***************************************************************************/

const ProjectPanels = Loadable(lazy(() => import('./ProjectPanels')));

const SubPanelsOverview = Loadable(
  lazy(() => import('./sub-panels/SubPanelOverview'))
);

const ProjectListOverview = Loadable(
  lazy(() => import('./list-view/ProjectsList'))
);

const ProjectDetails1 = Loadable(
  lazy(() => import('./sub-panels/SubPanelDetailsOverview'))
);

const ProjectDetails2 = Loadable(
  lazy(() => import('./list-view/ProjectDetailsOverview'))
);

const PanelSettings = Loadable(
  lazy(() => import('./panel-settings/PanelSettingsOverview'))
);

const SubPanelSettings = Loadable(
  lazy(() => import('./sub-panel-settings/SubPanelSettingsOverview'))
);

//** projects routes components */
//***************************************************************************/
//***************************************************************************/

const projectsDatabaseRoutes = [
  //** projects routes */
  {
    path: '/project-panels',
    element: <ProjectPanels />,
  },
  {
    path: '/project-panels/sub-panels',
    element: <SubPanelsOverview />,
  },
  {
    path: '/projects/list-view',
    element: <ProjectListOverview />,
  },
  {
    path: '/projects/project-details/:id',
    element: <ProjectDetails1 />,
  },
  {
    path: '/projects/project-details-by-list/:id',
    element: <ProjectDetails2 />,
  },
  {
    path: '/project-panels/panel-settings',
    element: <PanelSettings />,
  },
  {
    path: '/project-panels/sub-panel-settings',
    element: <SubPanelSettings />,
  },
];

export default projectsDatabaseRoutes;

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Sunday 29 January 2023 00:43:13
*/
