/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Friday 07 January 2022 01:38:27
*/

import {
  Button,
  Table,
  TableCell as tableCell,
  Autocomplete,
  Card,
  Stack,
  FormControlLabel,
  Box,
  styled,
} from '@mui/material';

import { bgTeal } from 'app/components/CustomComponents/Buttons';
import { FlexAlignCenter } from 'app/components/FlexBox';
import { Link } from 'react-router-dom';
import { red } from '@mui/material/colors';
import { FlexBetween } from 'app/components/FlexBox';
import { TextValidator } from 'react-material-ui-form-validator';
import { H1, H5, Paragraph } from 'app/components/Typography';
import { convertHexToRGB } from 'utils';

export const Container = styled('div')(({ theme }) => ({
  margin: '30px',
  [theme.breakpoints.down('sm')]: {
    margin: '16px',
  },
  '& .breadcrumb': {
    marginTop: '-0.75rem',
    marginBottom: '1.8rem',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '16px',
    },
  },
}));

export const StyledDiv = styled('div')(() => ({}));

export const AnalyticsRoot = styled('div')(({ theme }) => ({
  margin: '30px',
  [theme.breakpoints.down('sm')]: {
    margin: '16px',
  },
}));

export const FlexBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const FlexBoxDashBoard = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '24px',
}));

export const JustifyBox = styled(FlexBox)(() => ({
  justifyContent: 'center',
}));

export const JWTRoot = styled(JustifyBox)(() => ({
  //background: '#a6a6a6',
  background: '#e0e0e0',
  '& .card': {
    maxWidth: 800,
    borderRadius: 12,
    margin: '1rem',
  },
  '& .buttonProgress': {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export const Item = (props) => {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        textAlign: 'center',
        fontFamily: 'Yatra One',
        borderRadius: 2.5,
        marginTop: '4em',
        background: '#cd9495',
        ...sx,
      }}
      {...other}
    />
  );
};

export const JustifyBox2 = styled(FlexBox)(() => ({
  maxWidth: 320,
  flexDirection: 'column',
  justifyContent: 'center',
}));

export const IMG = styled('img')(() => ({
  width: '100%',
  marginBottom: '32px',
}));

export const NotFoundRoot = styled(FlexBox)(() => ({
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh !important',
}));

export const AppName = styled('h1')(() => ({
  fontSize: 32,
}));

export const Error = styled('p')(() => ({
  color: '#dc143c',
}));

export const TopContainer = styled('div')(() => ({}));

export const ContentBox = styled(FlexBox)(() => ({
  flexDirection: 'column',
  borderRadius: '0.25rem',
}));

export const ContentBox2 = styled(Box)(() => ({
  height: '100%',
  padding: '30px',
  position: 'relative',
  background: 'rgba(0, 0, 0, 0.01)',
}));

export const TableCell = styled(tableCell)(() => ({
  fontWeight: '500',
}));

export const TableCellFlexStart = styled(tableCell)(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
  fontWeight: '500',
}));

export const TableCellFlexEnd = styled(tableCell)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  fontWeight: '500',
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  fontSize: '13px',
  marginBottom: '16px',
  fontWeight: '500',
  color: theme.palette.text.primary,
  '& span, svg': {
    fontSize: '1.25rem',
    marginRight: '16px',
  },
}));

export const StyledButton2 = styled(Button)(({ theme }) => ({
  color: '#fff',
  paddingLeft: '28px',
  paddingRight: '28px',
  overflow: 'hidden',
  borderRadius: '300px',
  background: theme.palette.primary.main,
}));

export const StyledAnchor = styled('a')(({ theme }) => ({
  marginBottom: '16px',
  fontWeight: '500',
  color: theme.palette.text.primary,
  '& span, svg': {
    fontSize: '1.25rem',
    marginRight: '16px',
  },
}));

export const StyledLink = styled(Link)(({ theme }) => ({
  marginBottom: '16px',
  fontWeight: '500',
  color: theme.palette.text.primary,
  '& span, svg': {
    fontSize: '1.25rem',
    marginRight: '16px',
  },
}));

export const StyledLink2 = styled(Link)(() => ({
  fontWeight: '500',
  marginBottom: '16px',
}));

export const StyledLinkBlock = styled(Link)(() => ({
  fontWeight: '500',
  marginBottom: '0.6rem',
  display: 'block',
}));

export const StyledSpan = styled('span')(() => ({}));

export const StyledBlockSpan = styled('span')(() => ({
  display: 'block',
}));

export const StyledSpanFlexEnd = styled('span')(() => ({
  display: 'flex',
  justifyContent: 'right',
}));

export const PrinterFooterH5 = styled(H5)(() => ({
  marginBottom: 2,
  marginTop: 3,
  textAlign: 'center',
}));

const textError = red[500];
const textSuccess = '#009a00';
const textPrimary = '#1976d2';

export const TextField = styled(TextValidator)(() => ({
  width: '100%',
  marginBottom: '16px',
}));

export const AutoComplete = styled(Autocomplete)(() => ({
  width: '100%',
}));

export const FormHandlerBox = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const ImageIcon = styled('img')(() => ({
  marginRight: '6px',
}));

export const PrimaryText = styled('p')(() => ({
  color: textPrimary,
  marginTop: '-10px',
}));

export const SuccessText = styled('p')(() => ({
  color: textSuccess,
  marginTop: '-10px',
}));

export const ErrorText = styled('p')(() => ({
  color: textError,
  marginTop: '-10px',
}));

export const FlexEndBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
}));

export const Heading = styled(H1)(() => ({
  fontSize: '40px',
  textAlign: 'center',
  fontWeight: '500',
  marginBottom: '32px',
}));

export const StyledTable = styled(Table)(({ theme }) => ({
  marginBottom: 1,
  '& thead': {
    '& tr': {
      background: theme.palette.background.default,
      '& th': {
        paddingLeft: 0,
        paddingRight: 0,
      },
      '& th:first-of-type': {
        paddingLeft: '10px !important',
        [theme.breakpoints.down('sm')]: {
          paddingLeft: '16px !important',
        },
      },
    },
  },
  '& tbody': {
    '& tr': {
      '& td': {
        paddingLeft: 0,
        textTransform: 'capitalize',
      },
      '& td:first-of-type': {
        textTransform: 'capitalize',
        paddingLeft: '2px !important',
        [theme.breakpoints.down('sm')]: {
          paddingLeft: '6px !important',
        },
      },
    },
  },
}));

export const StyledP = styled(Paragraph)(({ theme }) => ({
  fontSize: '16px',
  textAlign: 'center',
  marginBottom: '64px',
  color: theme.palette.text.secondary,
}));

export const StyledParagraph = styled(Paragraph)(({ theme }) => ({
  fontSize: '14px',
  marginBottom: '10px',
  color: theme.palette.text.secondary,
}));

export const StyledCard = styled(Card)(({ theme, index, tabIndex }) => ({
  margin: '8px',
  padding: '22px 32px',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  border:
    index === tabIndex &&
    `1px solid rgba(${convertHexToRGB(theme.palette.primary.main)}, 1)`,
  '& .icon': {
    fontSize: '40px',
    marginBottom: '8px',
    color: index === tabIndex && theme.palette.primary.main,
  },
}));

export const Title = styled('span')(() => ({
  fontSize: '1rem',
  fontWeight: '500',
  textTransform: 'capitalize',
}));

export const SubTitle = styled('span')(({ theme }) => ({
  fontSize: '0.875rem',
  color: theme.palette.text.secondary,
}));

export const Header = styled(Title)(() => ({
  marginBottom: 2,
  color: 'rgba(255, 255, 255, 0.87)',
}));

//** styled components */
export const ContentWrapper = styled(Box)(({ theme }) => ({
  zIndex: 1,
  marginTop: 55,
  position: 'relative',
  [theme.breakpoints.down('sm')]: { paddingLeft: 20, paddingRight: 20 },
}));

export const ImageWrapper = styled(Box)(({ theme }) => ({
  width: 100,
  height: 100,
  margin: 'auto',
  overflow: 'hidden',
  borderRadius: '50%',
  border: '2px solid',
  borderColor: 'white',
  backgroundColor: theme.palette.primary[200],
}));

export const EarningBox = styled(Box)(({ theme }) => ({
  width: 130,
  paddingTop: 8,
  paddingBottom: 8,
  textAlign: 'center',
  borderRadius: '8px',
  border: `1px solid ${theme.palette.grey[100]}`,
}));

//** styled components */
export const EarningBoxWrapper = styled(FlexBetween)(({ theme }) => ({
  [theme.breakpoints.down(555)]: {
    flexDirection: 'column',
    '& > .MuiButton-root': { width: '100%' },
  },
  [theme.breakpoints.down(706)]: {
    '& > .MuiButton-root': { marginTop: 16 },
  },
}));

export const StyledStack = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.down(555)]: {
    width: '100%',
    flexDirection: 'column',
    '& > .MuiBox-root': { marginLeft: 0, width: '100%', marginBottom: 16 },
  },
}));

export const FileSideNavHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  marginTop: '8px',
  marginBottom: '16px',
  paddingLeft: '16px',
  alignItems: 'center',
}));

export const StyledCardFile = styled(Card)(({ theme }) => ({
  padding: '16px',
  marginBottom: '16px',
  position: 'relative',
}));

export const StyledLabel = styled(FormControlLabel)(({ theme }) => ({
  height: 32,
}));

export const Badge = styled('small')(({ theme }) => ({
  color: theme.palette.primary.main,
  background: `rgba(var(--primary), 0.15)`,
}));

export const FileStorageCard = styled(Card)(() => ({
  textAlign: 'center',
  position: 'relative',
  height: '85% !important',
  '&:hover': { '& .image-box-overlay': { opacity: 1 } },
  background: '#e0e0e0',
}));

export const IMGFile = styled('img')(() => ({}));

export const FileExtensionBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  justifyContent: 'center',
}));

export const ImageBox = styled(FlexAlignCenter)(() => ({
  top: 0,
  left: 0,
  right: 0,
  zIndex: 2,
  bottom: 0,
  opacity: 0,
  position: 'absolute',
  background: 'rgba(0, 0, 0, 0.74)',
  transition: 'all 250ms ease-in-out',
}));

export const FileSize = styled(Box)(({ theme }) => ({
  top: 4,
  right: 0,
  zIndex: 4,
  margin: 0,
  color: 'white',
  fontWeight: '500',
  position: 'absolute',
  borderTopLeftRadius: 26,
  borderBottomLeftRadius: 26,
  padding: '5px 5px 4px 12px',
  background: bgTeal,
}));

export const ChartHeader = styled(FlexBox)(({ theme }) => ({
  padding: '.8rem 1.25rem',
  justifyContent: 'space-between',
  borderBottom: `1px solid ${`rgba(${convertHexToRGB(
    theme.palette.text.disabled
  )}, 0.2)`}`,
  [theme.breakpoints.down('md')]: {
    padding: '10px 15px',
  },
}));

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Monday 24 April 2023 01:35:14
*/
