/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Wednesday 16 November 2022 05:00:22
*/

import { lazy } from 'react';
import Loadable from 'app/components/Loadable/Loadable';

//** clients routes components*/
//***************************************************************************/
//***************************************************************************/

const Clients = Loadable(lazy(() => import('./clients/Clients')));

const ClientsAccount = Loadable(
  lazy(() => import('./clients/clients-account/ClientsAccountOverview'))
);

const ClientsSettings = Loadable(
  lazy(() => import('./clients/clients-settings/ClientsSettingsOverview'))
);

//** clients routes components */
//***************************************************************************/
//***************************************************************************/

const clientsDatabaseRoutes = [
  //** clients routes */
  {
    path: '/clients/account',
    element: <Clients />,
  },
  {
    path: '/clients/clients-account',
    element: <ClientsAccount />,
  },
  {
    path: '/clients/clients-settings',
    element: <ClientsSettings />,
  },
];

export default clientsDatabaseRoutes;

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Wednesday 16 November 2022 17:51:42
*/
