/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Friday 27 January 2023 04:20:24
*/

import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  GetAllProjectPanels,
  CreateProjectPanel,
  UpdateProjectPanelById,
  DeleteProjectPanelById,
} from 'app/services/project-panels/ProjectPanels.services';

import {
  GetAllProjectSubPanels,
  CreateProjectSubPanel,
  UpdateProjectSubPanelById,
  DeleteProjectSubPanelById,
} from 'app/services/project-panels/ProjectSubPanels.services';

import {
  GetAllSubPanelParticipants,
  CreateSubPanelParticipant,
  UpdateSubPanelParticipantById,
  DeleteSubPanelParticipantById,
} from 'app/services/project-panels/SubPanelParticipants.services';

import store from 'app/store/store';

//***************************************************************/
//***************************************************************/

export const getAllProjectPanels = createAsyncThunk(
  'project-panels/getAll',
  async () => {
    const res = await GetAllProjectPanels();
    return res;
  }
);

export const createProjectPanel = createAsyncThunk(
  'project-panels/create',
  async (data, { getState }) => {
    const res = await CreateProjectPanel(data);
    const { projectPanels } = getState().projectPanels;
    const projectPanelsState = [...projectPanels, res];
    return { projectPanelsState };
  }
);

export const updateProjectPanelById = createAsyncThunk(
  'project-panels/update',
  async ({ panelId, ...data }) => {
    const res = await UpdateProjectPanelById(panelId, data);

    //** get sub panel from session storage */
    const subPanel = sessionStorage.getItem('subPanel');
    const parsedSubPanel = JSON.parse(subPanel);

    const updatePanel = { ...res, subPanel: parsedSubPanel };
    const { projectPanels } = store.getState().projectPanels;
    const filteredProjectPanels = projectPanels?.filter(
      (t) => t.panelId !== panelId
    );

    const projectPanelsState = [updatePanel, ...filteredProjectPanels];
    return { projectPanelsState };
  }
);

export const deleteProjectPanelById = createAsyncThunk(
  'project-panels/delete',
  async (panelId) => {
    await DeleteProjectPanelById(panelId);
    const { projectPanels } = store.getState().projectPanels;
    const projectPanelsState = projectPanels?.filter(
      (t) => t.panelId !== panelId
    );
    return { projectPanelsState };
  }
);

//***************************************************************/
//***************************************************************/

export const getAllProjectSubPanels = createAsyncThunk(
  'project-sub-panel/getAll',
  async () => {
    const res = await GetAllProjectSubPanels();
    return res;
  }
);

export const createProjectSubPanel = createAsyncThunk(
  'project-sub-panel/create',
  async (data, { getState }) => {
    const res = await CreateProjectSubPanel(data);
    const { projectSubPanels } = getState().projectPanels;
    const projectSubPanelsState = [...projectSubPanels, res];
    return { projectSubPanelsState };
  }
);

export const updateProjectSubPanelById = createAsyncThunk(
  'project-sub-panel/update',
  async ({ subPanelId, ...data }) => {
    const res = await UpdateProjectSubPanelById(subPanelId, data);

    const { projectSubPanels } = store.getState().projectPanels;
    const filteredProjectSubPanels = projectSubPanels?.filter(
      (t) => t.subPanelId !== subPanelId
    );
    const projectSubPanelsState = [res, ...filteredProjectSubPanels];
    return { projectSubPanelsState };
  }
);

export const deleteProjectSubPanelById = createAsyncThunk(
  'project-sub-panel/delete',
  async (subPanelId) => {
    await DeleteProjectSubPanelById(subPanelId);
    const { projectSubPanels } = store.getState().projectPanels;
    const projectSubPanelsState = projectSubPanels?.filter(
      (t) => t.subPanelId !== subPanelId
    );
    return { projectSubPanelsState };
  }
);

//***************************************************************/
//***************************************************************/

export const getAllSubPanelParticipants = createAsyncThunk(
  'project-sub-panel-participants/getAll',
  async () => {
    const res = await GetAllSubPanelParticipants();
    return res;
  }
);

export const createSubPanelParticipant = createAsyncThunk(
  'project-sub-panel-participants/create',
  async (data, { getState }) => {
    const res = await CreateSubPanelParticipant(data);
    const { subPanelParticipants } = getState().projectPanels;
    const subPanelParticipantsState = [...subPanelParticipants, res];
    return { subPanelParticipantsState };
  }
);

export const updateSubPanelParticipantById = createAsyncThunk(
  'project-sub-panel-participants/update',
  async ({ panelParticipantId, ...data }) => {
    const res = await UpdateSubPanelParticipantById(panelParticipantId, data);
    const { subPanelParticipants } = store.getState().projectPanels;

    const filteredProjectSubPanels = subPanelParticipants?.filter(
      (t) => t.panelParticipantId !== panelParticipantId
    );

    const subPanelParticipantsState = [res, ...filteredProjectSubPanels];
    return { subPanelParticipantsState };
  }
);

export const deleteSubPanelParticipantById = createAsyncThunk(
  'project-sub-panel-participants/delete',
  async (panelParticipantId) => {
    await DeleteSubPanelParticipantById(panelParticipantId);
    const { subPanelParticipants } = store.getState().projectPanels;
    const subPanelParticipantsState = subPanelParticipants?.filter(
      (t) => t.panelParticipantId !== panelParticipantId
    );
    return { subPanelParticipantsState };
  }
);

//***************************************************************/
//***************************************************************/

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Wednesday 15 March 2023 15:01:15
*/