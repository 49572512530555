/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Sunday 23 April 2023 19:00:32
*/

import { lazy } from 'react';
import Loadable from 'app/components/Loadable/Loadable';

//** research resources routes components*/
//***************************************************************************/
//***************************************************************************/

const ResearchResources = Loadable(
  lazy(() => import('./research-resources/ResearchResources'))
);

const ResearchResourceOverview = Loadable(
  lazy(() =>
    import('./research-resources/research-resources-list-view/ResearchResourcesList')
  )
);

const ResearchResourcesDetails2 = Loadable(
  lazy(() =>
    import(
      './research-resources/research-resources-list-view/ResearchResourceDetailsOverview'
    )
  )
);

//** training resources routes components*/
//***************************************************************************/
//***************************************************************************/

const TrainingResources = Loadable(
  lazy(() => import('./training-resources/TrainingResources'))
);

const TrainingResourceOverview = Loadable(
  lazy(() =>
    import('./training-resources/training-resources-list-view/TrainingResourcesList')
  )
);

const TrainingResourcesDetails2 = Loadable(
  lazy(() =>
    import(
      './training-resources/training-resources-list-view/TrainingResourceDetailsOverview'
    )
  )
);

//** in house resources routes components */
//***************************************************************************/
//***************************************************************************/

const inHouseResourcesRoutes = [
  //** research resources routes */
  {
    path: '/resources/research-resources',
    element: <ResearchResources />,
  },
  {
    path: '/resources/research-resources-overview',
    element: <ResearchResourceOverview />,
  },
  {
    path: '/resources/research-resources-overview/:id',
    element: <ResearchResourcesDetails2 />,
  },

  //** training resources routes */
  {
    path: '/resources/training-resources',
    element: <TrainingResources />,
  },
  {
    path: '/resources/training-resources-overview',
    element: <TrainingResourceOverview />,
  },
  {
    path: '/resources/training-resources-overview/:id',
    element: <TrainingResourcesDetails2 />,
  },
];

export default inHouseResourcesRoutes;

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Monday 24 April 2023 11:37:04
*/
