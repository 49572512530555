/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Thursday 13 October 2022 01:03:51
*/

import { lazy } from 'react';

import Loadable from 'app/components/Loadable/Loadable';

const ParticipantsProfileOverview = Loadable(
  lazy(() => import('./participants-database/ParticipantsProfile'))
);

const ParticipantsProfileList = Loadable(
  lazy(() => import('./participants-database/ParticipantsProfileList'))
);

const ParticipantsProfileDetailsViewer = Loadable(
  lazy(() =>
    import(
      './participants-database/participants-details/ParticipantsDetailsOverview'
    )
  )
);

const ParticipantsProfileFormOverview = Loadable(
  lazy(() =>
    import(
      './participants-database/create-participant-profile/ParticipantsProfileFormOverview'
    )
  )
);

//*****************************************************************/
//*****************************************************************/

const ExpertsProfileOverview = Loadable(
  lazy(() => import('./experts-database/ExpertsProfile'))
);

const ExpertsProfileList = Loadable(
  lazy(() => import('./experts-database/ExpertsProfileList'))
);

const ExpertsProfileDetailsViewer = Loadable(
  lazy(() =>
    import('./experts-database/experts-details/ExpertsDetailsOverview')
  )
);

const ExpertsProfileFormOverview = Loadable(
  lazy(() =>
    import(
      './experts-database/create-experts-profile/ExpertsProfileFormOverview'
    )
  )
);

//*****************************************************************/
//*****************************************************************/

const PrizeDraw = Loadable(lazy(() => import('./prize-draw/PrizeDraw')));

const PrizeDrawOverview = Loadable(
  lazy(() => import('./prize-draw/prize-draw/PrizeDrawOverview'))
);

//*****************************************************************/
//*****************************************************************/

const NetizensAndCitizensRoutes = [
  {
    path: '/netizens/participants-database',
    element: <ParticipantsProfileOverview />,
  },
  {
    path: '/netizens/participants-profile-list',
    element: <ParticipantsProfileList />,
  },
  {
    path: '/netizens/participants-profile/:id',
    element: <ParticipantsProfileDetailsViewer />,
  },
  {
    path: '/netizens/create-participant-profile',
    element: <ParticipantsProfileFormOverview />,
  },

  //*****************************************************************/
  //*****************************************************************/
  {
    path: '/netizens/experts-database',
    element: <ExpertsProfileOverview />,
  },
  {
    path: '/netizens/experts-profile-list',
    element: <ExpertsProfileList />,
  },
  {
    path: '/netizens/experts-profile/:id',
    element: <ExpertsProfileDetailsViewer />,
  },
  {
    path: '/netizens/create-expert-profile',
    element: <ExpertsProfileFormOverview />,
  },
  //*****************************************************************/
  //*****************************************************************/
  {
    path: '/netizens/prize-draw',
    element: <PrizeDraw />,
  },
  {
    path: '/netizens/prize-draw/prize-draw',
    element: <PrizeDrawOverview />,
  },
  //*****************************************************************/
  //*****************************************************************/
];

export default NetizensAndCitizensRoutes;

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Sunday 01 January 2023 18:47:22
*/
