/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Friday 14 January 2022 20:12:11
*/

import { lazy } from 'react';
import Loadable from 'app/components/Loadable/Loadable';

//***************************************************************************/
//***************************************************************************/
//** expenses routes components*/

const Expenses = Loadable(lazy(() => import('./expenses/Expenses')));

const ExpensesAccount = Loadable(
  lazy(() => import('./expenses/expenses-account/ExpensesAccountOverview'))
);

const ExpensesSettings = Loadable(
  lazy(() => import('./expenses/expenses-settings/ExpensesSettingsOverview'))
);

//***************************************************************************/
//***************************************************************************/
//** expenses analysis components*/
const ExpensesAnalysis = Loadable(
  lazy(() => import('./expenses/expenses-analysis/ExpensesAnalysisOverview'))
);

//***************************************************************************/
//***************************************************************************/

const ledgersRoutes = [
  //** expenses routes  */
  {
    path: '/expenses-tracker',
    element: <Expenses />,
  },
  {
    path: '/expenses-tracker/accounts',
    element: <ExpensesAccount />,
  },
  {
    path: '/expenses-tracker/expenses-settings',
    element: <ExpensesSettings />,
  },
  //** balance & analysis route */
  {
    path: '/expenses-tracker/expenses-analysis',
    element: <ExpensesAnalysis />,
  },
];

export default ledgersRoutes;

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Saturday 22 April 2023 03:14:24
*/