/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Sunday 23 April 2023 16:27:20
*/

import http from '../http';

//** get all training resources http request */
export const GetAllTrainingResources = async () => {
  try {
    const res = await http.get('/training-resources');
    return res.data.data;
  } catch (err) {}
};

//** get training resource by id http request */
export const GetTrainingResourceById = async (id) => {
  try {
    const res = await http.get(`/training-resources/${id}`);
    return res.data.data;
  } catch (err) {
    return err;
  }
};

//** create training resource http request */
export const CreateTrainingResource = async (data) => {
  try {
    const res = await http.post('/training-resources', data);
    return res.data.data;
  } catch (err) {
    return err;
  }
};

//** update training resource by id http request */
export const UpdateTrainingResourceById = async (id, data) => {
  const res = await http.patch(`/training-resources/${id}`, data);
  return res.data.data;
};

//** delete training resource http request */
export const DeleteTrainingResourceById = async (id) => {
  const res = await http.delete(`/training-resources/${id}`);
  return res.data;
};

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Sunday 23 April 2023 16:27:38
*/
