/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Sunday 23 April 2023 16:27:20
*/

import http from '../http';

//** get all research resources http request */
export const GetAllResearchResources = async () => {
  try {
    const res = await http.get('/research-resources');
    return res.data.data;
  } catch (err) {}
};

//** get research resource by id http request */
export const GetResearchResourceById = async (id) => {
  try {
    const res = await http.get(`/research-resources/${id}`);
    return res.data.data;
  } catch (err) {
    return err;
  }
};

//** create research resource http request */
export const CreateResearchResource = async (data) => {
  try {
    const res = await http.post('/research-resources', data);
    return res.data.data;
  } catch (err) {
    return err;
  }
};

//** update research resource by id http request */
export const UpdateResearchResourceById = async (id, data) => {
  const res = await http.patch(`/research-resources/${id}`, data);
  return res.data.data;
};

//** delete research resource http request */
export const DeleteResearchResourceById = async (id) => {
  const res = await http.delete(`/research-resources/${id}`);
  return res.data;
};

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Sunday 23 April 2023 16:31:07
*/
