import { lazy } from 'react';
import Loadable from 'app/components/Loadable/Loadable';

import useAuth from 'app/hooks/useAuth';
import { Navigate } from 'react-router-dom';

const RouteGuard = ({ children }) => {
  const { userVerified: isVerified } = useAuth();
  return <>{isVerified ? children : <Navigate to="/login" />}</>;
};

//** session routes components*/
//***************************************************************************/
//***************************************************************************/

const NotFound = Loadable(lazy(() => import('./NotFound')));
const ForgotPassword = Loadable(lazy(() => import('./ForgotPassword')));
const JWTLogin = Loadable(lazy(() => import('./login/JwtLogin')));
const ResetLoginPassword = Loadable(
  lazy(() => import('./login/ResetLoginPassword'))
);

const AuthOTPVerification = Loadable(
  lazy(() => import('./login/OTPLoginVerification'))
);

const PasswordSetupPage = Loadable(
  lazy(() => import('./login/PasswordSetupPage'))
);

//** login routes */
//***************************************************************************/
//***************************************************************************/

const sessionRoutes = [
  {
    path: '/login',
    element: <JWTLogin />,
  },
  {
    path: '/login-verification',
    element: (
      <RouteGuard>
        <AuthOTPVerification />
      </RouteGuard>
    ),
  },
  {
    path: '/password-setup',
    element: <PasswordSetupPage />,
  },
  {
    path: '/reset-login-password',
    element: <ResetLoginPassword />,
  },
  {
    path: '/forgot-password',
    element: <ForgotPassword />,
  },
  {
    path: '/session/404',
    element: <NotFound />,
  },
];

export default sessionRoutes;
