/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Tuesday 20 September 2022 12:17:00
*/

import { createSlice } from '@reduxjs/toolkit';

import {
  getAllInvoiceHistory,
  createInvoiceHistory,
  updateInvoiceHistoryById,
  deleteInvoiceHistoryById,
} from '../thunk/invoiceHistory.Thunk';

const initialState = {
  invoiceHistory: [],
};

export const invoiceHistorySlice = createSlice({
  name: 'invoiceHistory',
  initialState,
  extraReducers: (builder) => {
    //** invoice history slice */
    builder.addCase(getAllInvoiceHistory.fulfilled, (state, { payload }) => {
      state.invoiceHistory = payload;
    });

    builder.addCase(createInvoiceHistory.fulfilled, (state, { payload }) => {
      const { invoiceHistoryState } = payload;
      state.invoiceHistory = invoiceHistoryState;
    });

    builder.addCase(
      updateInvoiceHistoryById.fulfilled,
      (state, { payload }) => {
        const { invoiceHistoryState } = payload;
        state.invoiceHistory = invoiceHistoryState;
      }
    );

    builder.addCase(
      deleteInvoiceHistoryById.fulfilled,
      (state, { payload }) => {
        const { invoiceHistoryState } = payload;
        state.invoiceHistory = invoiceHistoryState;
      }
    );
  },
});

export default invoiceHistorySlice.reducer;

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Monday 22 May 2023 17:27:31
*/
