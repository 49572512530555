/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Friday 24 February 2023 15:55:42
*/

import http from '../http';

//** get all file storage history */
export const GetAllFileStorageHistory = async () => {
  try {
    const res = await http.get('/file-storage-history');
    return res.data.data;
  } catch (err) {}
};

//** get file storage history by id */
export const GetFileStorageHistoryById = async (id) => {
  try {
    const res = await http.get(`/file-storage-history/${id}`);
    return res.data.data;
  } catch (err) {
    return err;
  }
};

//** create file storage history */
export const CreateFileStorageHistory = async (data) => {
  try {
    const res = await http.post('/file-storage-history/', data);
    return res.data.data;
  } catch (err) {
    return err;
  }
};

//** delete file storage history */
export const DeleteFileStorageHistoryById = async (id) => {
  const res = await http.delete(`/file-storage-history/${id}`);
  return res.data;
};

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Friday 24 February 2023 15:57:12
*/
