/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Sunday 16 January 2022 15:10:23
*/

import http from '../http';

//** get all expenses account */
export const GetAllExpensesAccount = async () => {
  try {
    const res = await http.get('/accounts/expenses-acc');
    return res.data.data;
  } catch (err) {}
};

//** get expenses account by id */
export const GetExpensesAccountById = async (id) => {
  try {
    const res = await http.get(`/accounts/expenses-acc/${id}`);
    return res.data.data?.expAccTrxns;
  } catch (err) {}
};

//** create expenses account */
export const CreateExpensesAccount = async (data) => {
  try {
    const res = await http.post('/accounts/expenses-acc', data);
    return res.data.data;
  } catch (err) {
    return err;
  }
};

//** update expenses account by id*/
export const UpdateExpensesAccountById = async (id, data) => {
  try {
    const res = await http.patch(`/accounts/expenses-acc/${id}`, data);
    return res.data.data;
  } catch (err) {
    return err;
  }
};

//** delete expenses account by id */
export const DeleteExpensesAccountById = async (id) => {
  try {
    const res = await http.delete(`/accounts/expenses-acc/${id}`);
    return res.data;
  } catch (err) {
    return err;
  }
};

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Saturday 14 January 2023 22:42:29
*/
