/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Wednesday 13 April 2022 13:54:01
*/

import http from '../http';

//** get all app config */
export const GetAllAppConfig = async () => {
  try {
    const res = await http.get('/app-config');
    return res.data.data;
  } catch (err) {}
};

//** get app config by id */
export const GetAppConfigById = async (id) => {
  try {
    const res = await http.get(`/app-config/${id}`);
    return res.data.data;
  } catch (err) {}
};

//** create app config */
export const CreateAppConfig = async (data) => {
  try {
    const res = await http.post('/app-config/', data);
    return res.data.data;
  } catch (err) {
    return err;
  }
};

//** update app config by id */
export const UpdateAppConfigById = async (id, data) => {
  try {
    const res = await http.patch(`/app-config/${id}`, data);
    return res.data.data;
  } catch (err) {
    return err;
  }
};

//** delete app config by id */
export const DeleteAppConfigById = async (id) => {
  try {
    const res = await http.delete(`/app-config/${id}`);
    return res.data;
  } catch (err) {
    return err;
  }
};

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Tuesday 14 March 2023 17:04:11
*/
