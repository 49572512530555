/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Wednesday 03 July 2024 18:00:00
*/

import http from '../http';

//** get all project applications http request */
export const GetAllProjectApplications = async () => {
  try {
    const res = await http.get('/project-applications');
    return res.data.data;
  } catch (err) {}
};

//** get project application by id http request */
export const GetProjectApplicationById = async (id) => {
  try {
    const res = await http.get(`/project-applications/${id}`);
    return res.data.data;
  } catch (err) {
    return err;
  }
};

//** create project application http request */
export const CreateProjectApplication = async (data) => {
  try {
    const res = await http.post('/project-applications', data);
    return res.data.data;
  } catch (err) {
    return err;
  }
};

//** update project application by id http request */
export const UpdateProjectApplicationById = async (id, data) => {
  const res = await http.patch(`/project-applications/${id}`, data);
  return res.data.data;
};

//** delete project application http request */
export const DeleteProjectApplicationById = async (id) => {
  const res = await http.delete(`/project-applications/${id}`);
  return res.data;
};

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Wednesday 03 July 2024 19:04:00
*/
