/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Wednesday 16 November 2022 06:34:34
*/

import { createSlice } from '@reduxjs/toolkit';

import {
  getAllClients,
  createClient,
  updateClientById,
  deleteClientById,
  getAllClientsRep,
  createClientRep,
  updateClientRepById,
  deleteClientRepById,
} from '../thunk/clients.Thunk';

const initialState = {
  clients: [],
  clientsRep: [],
};

export const clientsSlice = createSlice({
  name: 'clientsSlice',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getAllClients.fulfilled, (state, { payload }) => {
      state.clients = payload;
    });

    builder.addCase(createClient.fulfilled, (state, { payload }) => {
      const { clientsState } = payload;
      state.clients = clientsState;
    });

    builder.addCase(updateClientById.fulfilled, (state, { payload }) => {
      const { clientsState } = payload;
      state.clients = clientsState;
    });

    builder.addCase(deleteClientById.fulfilled, (state, { payload }) => {
      const { clientsState } = payload;
      state.clients = clientsState;
    });

    //************************************************************/
    //************************************************************/

    builder.addCase(getAllClientsRep.fulfilled, (state, { payload }) => {
      state.clientsRep = payload;
    });

    builder.addCase(createClientRep.fulfilled, (state, { payload }) => {
      const { clientsRepState } = payload;
      state.clientsRep = clientsRepState;
    });

    builder.addCase(updateClientRepById.fulfilled, (state, { payload }) => {
      const { clientsRepState } = payload;
      state.clientsRep = clientsRepState;
    });

    builder.addCase(deleteClientRepById.fulfilled, (state, { payload }) => {
      const { clientsRepState } = payload;
      state.clientsRep = clientsRepState;
    });

    //************************************************************/
    //************************************************************/
  },
});

export default clientsSlice.reducer;

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Monday 22 May 2023 17:11:00
*/
