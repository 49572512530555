/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Wednesday 10 August 2022 05:03:25
*/

import http from '../http';

//** get all restore history */
export const GetAllRestoreHistory = async () => {
  try {
    const res = await http.get('/database-restore-history');
    return res.data.data;
  } catch (err) {}
};

//** get restore history by id */
export const GetRestoreHistoryById = async (id) => {
  try {
    const res = await http.get(`/database-restore-history/${id}`);
    return res.data.data;
  } catch (err) {}
};

//** create restore history */
export const CreateRestoreHistory = async (data) => {
  try {
    const res = await http.post('/database-restore-history', data);
    return res.data.data;
  } catch (err) {
    return err;
  }
};

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Wednesday 07 September 2022 13:12:41
*/
