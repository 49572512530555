/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Saturday 01 January 2022 00:00:00
*/

import { createContext, useEffect, useReducer } from 'react';

import http from 'app/services/http';
import jwtDecode from 'jwt-decode';
import { useCookies } from 'react-cookie';

import { databaseName } from 'app/constants/app.constants';

import { MatxLoading } from 'app/components';
import history from 'history.js';

const initialState = {
  isAuthenticated: false,
  loginVerified: false,
  adminVerified: false,
  userVerified: false,
  authOTPVerified: false,
  isInitialised: false,
  user: null,
  userData: null,
  //** the password is used as temp variable and discarded upon successful login */
  password: '',
};

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }
  const decodedToken = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;
  return decodedToken.exp > currentTime;
};

export const getCookie = (cookieName) => {
  const name = cookieName + '=';
  const cDecoded = decodeURIComponent(document.cookie); //to be careful
  const cookieArray = cDecoded.split('; ');
  let cookie;
  cookieArray.forEach((val) => {
    if (val.indexOf(name) === 0) cookie = val.substring(name.length);
  });
  return cookie;
};

const setSession = (accessToken) => {
  if (accessToken) {
    getCookie('authToken');
  } else {
    document.cookie =
      'authToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/';
    history.push('/');
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INIT': {
      const { isAuthenticated, user, userData } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        isVerified: false,
        user,
        userData,
      };
    }
    case 'LOGIN': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        user: user?.username,
        userData: user,
      };
    }
    case 'VERIFY_LOGIN': {
      const { user } = action.payload;

      return {
        ...state,
        user,
      };
    }
    case 'VERIFY_ADMIN': {
      const { user, adminVerified } = action.payload;

      return {
        ...state,
        user,
        adminVerified,
      };
    }
    case 'VERIFY_USER': {
      const { userVerified } = action.payload;

      return {
        ...state,
        userVerified,
      };
    }
    case 'ENABLE_AUTH_OTP': {
      const { authOTPVerified } = action.payload;

      return {
        ...state,
        authOTPVerified,
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    }
    case 'REGISTER': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialState,
  method: 'JWT',
  login: () => Promise.resolve(),
  passwordSetup: () => Promise.resolve(),
  verifyLogin: () => Promise.resolve(),
  verifyAuthOTPLogin: () => Promise.resolve(),
  verifyAdmin: () => Promise.resolve(),
  verifyAdminAndOTP: () => Promise.resolve(),
  changePassword: () => Promise.resolve(),
  changePasswordWithOTP: () => Promise.resolve(),
  setupAuthOTP: () => Promise.resolve(),
  enableAuthOTP: () => Promise.resolve(),
  disableAuthOTP: () => Promise.resolve(),
  secureLogin: () => Promise.resolve(),
  resetLogin: () => Promise.resolve(),
  resetPassword: () => Promise.resolve(),
  logout: () => {},
  register: () => Promise.resolve(),
});

export const AuthProvider = ({ children }) => {
  //** state */
  const [state, dispatch] = useReducer(reducer, initialState);

  // eslint-disable-next-line
  const [cookies, setCookie, removeCookie] = useCookies(['token']);

  //** login end point */
  const login = async (username, password) => {
    const response = await http.post('/login', {
      username,
      password,
    });

    sessionStorage.setItem('token', response?.data.token);

    //** using context to verify user, verified but not yet authenticated with otp */
    if (response.data.status === 200) {
      dispatch({
        type: 'VERIFY_USER',
        payload: {
          userVerified: true,
        },
      });
    }

    //** user data */
    const user = response.data;
    return user;
  };

  //** verify login end point */
  const verifyLogin = async (response) => {
    //** destructure from response */
    const { isInitialLogin, authKeyEnabled } = response?.data;

    //** set token to header */
    http.defaults.headers.common['Authorization'] =
      sessionStorage.getItem('token');

    //** get app config */
    const appConfig = await http.get(`/app-config`);

    //** app config  */
    const appConfigArray = appConfig?.data?.data;

    //** current currency config */
    const currencyKey = appConfigArray?.filter(
      (t) => t.configKey === 'currency'
    );

    if (!isInitialLogin && !authKeyEnabled) {
      //** set token to session storage */
      sessionStorage.setItem('token', response?.token);

      //** set currency to cookie */
      setCookie('token', response?.token, { path: '/' });

      //** set currency type to cookie */
      sessionStorage.setItem('currency', currencyKey[0].configValue1);

      //** user data */
      const user = response?.data;

      //** set cookies to session */
      setSession(getCookie('authToken'));

      //** dispatch login reducer function
      dispatch({
        type: 'LOGIN',
        payload: {
          isAuthenticated: true,
          user,
        },
      });
    }
  };

  //** verify auth OTP login */
  const verifyAuthOTPLogin = async (username, token) => {
    //** set token to header */
    http.defaults.headers.common['Authorization'] =
      sessionStorage.getItem('token');

    //** http request to verify auth otp */
    const response = await http.post('/verify-auth-otp-login', {
      username,
      token,
    });

    //** get app config */
    const appConfig = await http.get(`/app-config`);

    //** app config  */
    const appConfigArray = appConfig?.data?.data;

    //** current currency config */
    const currencyKey = appConfigArray?.filter(
      (t) => t.configKey === 'currency'
    );

    //** set currency type to cookie */
    sessionStorage.setItem('currency', currencyKey[0].configValue1);

    //** get user from response */
    const user = response.data.data;

    //** set token to cookie */
    setCookie('token', sessionStorage.getItem('token'), { path: '/' });

    //** dispatch login reducer function
    dispatch({
      type: 'LOGIN',
      payload: {
        isAuthenticated: true,
        user,
      },
    });

    return response.data.data;
  };

  //** verify admin end point */
  const verifyAdmin = async (username, password) => {
    const response = await http.post('/verify-admin', {
      username,
      password,
    });

    //** user data */
    let user = response.data.data;

    if (response.data.status === 200) {
      dispatch({
        type: 'VERIFY_ADMIN',
        payload: {
          user,
          adminVerified: true,
        },
      });
    }
  };

  //** verify admin with otp end point */
  const verifyAdminAndOTP = async (username, password, token) => {
    const response = await http.post('/verify-admin-and-otp', {
      username,
      password,
      token,
    });

    //** user data */
    let user = response.data.data;

    if (response.data.status === 200) {
      dispatch({
        type: 'VERIFY_ADMIN',
        payload: {
          user,
          adminVerified: true,
        },
      });
    }
  };

  //** change password */
  const changePassword = async (username, oldPassword, newPassword) => {
    const response = await http.post('/change-password', {
      username,
      oldPassword,
      newPassword,
    });

    return response.data.data;
  };

  //** change password with OTP */
  const changePasswordWithOTP = async (
    username,
    oldPassword,
    newPassword,
    token
  ) => {
    const response = await http.post('/change-password-with-otp', {
      username,
      oldPassword,
      newPassword,
      token,
    });

    return response.data.data;
  };

  //** password setup end point */
  const passwordSetup = async (adminId, password, passwordResetKey) => {
    //** set token to header */
    http.defaults.headers.common['Authorization'] =
      sessionStorage.getItem('token');

    const response = await http.post('/password-setup', {
      adminId,
      password,
      passwordResetKey,
    });

    sessionStorage.removeItem('token');

    //** user data */
    return response.data;
  };

  //** setup auth OTP */
  const setupAuthOTP = async (username) => {
    const response = await http.get(`/setup-authOTP/${username}`);
    return response.data.data;
  };

  //** enable auth OTP */
  const enableAuthOTP = async (adminId, username, password, secret, token) => {
    const response = await http.post('/enable-authOTP', {
      adminId,
      username,
      password,
      secret,
      token,
    });

    //** user data */
    let { isValid } = response.data.data;

    //** auth otp verification boolean */
    let authOTPVerified = isValid;

    if (authOTPVerified) {
      dispatch({
        type: 'ENABLE_AUTH_OTP',
        payload: {
          authOTPVerified,
        },
      });
    }
  };

  //** disable auth OTP */
  const disableAuthOTP = async (adminId, username, password, token) => {
    await http.post('/disable-authOTP', {
      adminId,
      username,
      password,
      token,
    });
  };

  //** secure user login -- will change user logged in boolean => true upon successful login */
  const secureLogin = async (username) => {
    await http.post('/secure-login', {
      username,
    });
  };

  //** reset login */
  const resetLogin = async (username, password, email, token) => {
    const response = await http.post('/reset-login', {
      username,
      password,
      email,
      token,
    });
    return response.data.data;
  };

  //** reset password */
  const resetPassword = async (username, passwordResetKey, email, token) => {
    const response = await http.post('/reset-password', {
      username,
      passwordResetKey,
      email,
      token,
    });

    return response.data.data;
  };

  //** register end point */
  const register = async (email, username, password) => {
    const response = await http.post('/api/auth/register', {
      email,
      username,
      password,
    });

    const { accessToken, user } = response.data;
    setSession(accessToken);
    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  };

  //** logout end point */
  const logout = async () => {
    try {
      //** clear session storage */
      sessionStorage.clear();

      //** remove cookies  */
      setCookie('token', '', {
        path: '/',
      });

      //** remove cookies  */
      removeCookie('token', {
        path: '/',
      });

      //** database name
      const database = indexedDB.deleteDatabase(databaseName);

      //** on success */
      database.onsuccess = () => {
        //console.log('Deleted database successfully');
      };

      dispatch({ type: 'LOGOUT' });
      const response = await http.get('/logout');
      const data = await response;

      return data;
    } catch (err) {}
  };

  useEffect(() => {
    (async () => {
      try {
        //** set accessToken to session token */
        //console.log(getCookie('token'));
        const accessToken = getCookie('token');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          //** decoded jwt contains user data used application wide */
          const decodedToken = jwtDecode(accessToken);

          //** username only */
          const user = decodedToken.username;

          //** username && user details */
          const userData = decodedToken;

          dispatch({
            type: 'INIT',
            payload: {
              isAuthenticated: true,
              user,
              userData,
            },
          });
        } else {
          dispatch({
            type: 'INIT',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        dispatch({
          type: 'INIT',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    })();
  }, []);

  if (!state.isInitialised) {
    return <MatxLoading />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        verifyLogin,
        verifyAuthOTPLogin,
        verifyAdmin,
        verifyAdminAndOTP,
        changePassword,
        changePasswordWithOTP,
        passwordSetup,
        setupAuthOTP,
        enableAuthOTP,
        disableAuthOTP,
        secureLogin,
        resetLogin,
        resetPassword,
        logout,
        register,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Sunday 25 December 2022 20:55:29
*/
