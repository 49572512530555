/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Wednesday 03 July 2024 19:06:00
*/

import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  GetAllProjectApplications,
  CreateProjectApplication,
  UpdateProjectApplicationById,
  DeleteProjectApplicationById,
} from 'app/services/project-applications/ProjectApplications.services';

import store from 'app/store/store';

export const getAllProjectApplications = createAsyncThunk(
  'projectApplications/getAll',
  async () => {
    const res = await GetAllProjectApplications();
    return res;
  }
);

export const createProjectApplication = createAsyncThunk(
  'projectApplications/create',
  async (data, { getState }) => {
    const res = await CreateProjectApplication(data);
    const { projectApplications } = getState().projectApplications;
    const projectApplicationsState = [...projectApplications, res];
    return { projectApplicationsState };
  }
);

export const updateProjectApplicationById = createAsyncThunk(
  'projectApplications/update',
  async ({ projectId, ...data }) => {
    const res = await UpdateProjectApplicationById(projectId, data);
    const { projectApplications } = store.getState().projectApplications;
    const filteredProjectApplications = projectApplications?.filter(
      (t) => t.projectId !== projectId
    );
    const projectApplicationsState = [res, ...filteredProjectApplications];
    return { projectApplicationsState };
  }
);

export const deleteProjectApplicationById = createAsyncThunk(
  'projectApplications/delete',
  async (projectId) => {
    await DeleteProjectApplicationById(projectId);
    const { projectApplications } = store.getState().projectApplications;
    const projectApplicationsState = projectApplications?.filter(
      (t) => t.projectId !== projectId
    );
    return { projectApplicationsState };
  }
);

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Wednesday 03 July 2024 19:41:00
*/
