/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Wednesday 13 April 2022 15:50:02
*/

import { createAsyncThunk } from '@reduxjs/toolkit';

//** get app config */
import {
  GetAllAppConfig,
  GetAppConfigById,
  CreateAppConfig,
  UpdateAppConfigById,
  DeleteAppConfigById,
} from 'app/services/config/AppConfig.services';

import store from 'app/store/store';

//***************************************************************************/
//***************************************************************************/

//** app config thunk starts  */
export const getAllAppConfig = createAsyncThunk(
  'get-all-app-config',
  async () => {
    const res = await GetAllAppConfig();
    return res;
  }
);

export const getAppConfigById = createAsyncThunk(
  'get-app-config-byId',
  async (id) => {
    const res = await GetAppConfigById(id);
    return res;
  }
);

export const createAppConfig = createAsyncThunk(
  'create-app-config',
  async (data, { getState }) => {
    const res = await CreateAppConfig(data);
    const { appConfig } = getState().config;
    const appConfigState = [...appConfig, res];
    return { appConfigState };
  }
);

export const updateAppConfigById = createAsyncThunk(
  'update-app-config-byId',
  async ({ configId, ...rest }) => {
    const res = await UpdateAppConfigById(configId, rest);
    const { appConfig } = store.getState().config;
    const filteredAppConfig = appConfig.filter((t) => t.configId !== configId);
    const appConfigState = [...filteredAppConfig, res];
    return { appConfigState };
  }
);

export const deleteAppConfigById = createAsyncThunk(
  'delete-app-config-byId',
  async (configId) => {
    await DeleteAppConfigById(configId);
    const { appConfig } = store.getState().config;
    const filteredAppConfig = appConfig.filter((t) => t.configId !== configId);
    const appConfigState = [...filteredAppConfig];
    return { appConfigState };
  }
);

//** app config thunk ends  */

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Tuesday 14 March 2023 17:08:25
*/
