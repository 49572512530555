import React from 'react';
import { Navigate } from 'react-router-dom';

import useAuth from 'app/hooks/useAuth';

const AuthGuard = ({ children }) => {
  const { isAuthenticated } = useAuth();
  return <>{isAuthenticated ? children : <Navigate to="/login" />}</>;
};

export default AuthGuard;
