/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Wednesday 13 April 2022 15:50:02
*/

import { createAsyncThunk } from '@reduxjs/toolkit';

//** get backup history */
import {
  GetAllBackupHistory,
  GetBackupHistoryById,
  CreateBackupHistory,
} from 'app/services/backup-restore-history/DatabaseBackupHistory.services.js';

//** get restore history */
import {
  GetAllRestoreHistory,
  GetRestoreHistoryById,
  CreateRestoreHistory,
} from 'app/services/backup-restore-history/DatabaseRestoreHistory.services.js';

//***************************************************************************/
//***************************************************************************/

//** backup && restore thunk starts  */
export const getAllBackupHistory = createAsyncThunk(
  'get-all-backup-history',
  async () => {
    const res = await GetAllBackupHistory();
    return res;
  }
);

export const getBackupHistoryById = createAsyncThunk(
  'get-backup-history-byId',
  async (id) => {
    const res = await GetBackupHistoryById(id);
    return res;
  }
);

export const createBackupHistory = createAsyncThunk(
  'create-backup-history',
  async (data, { getState }) => {
    const res = await CreateBackupHistory(data);
    const { backupHistory } = getState().backupRestoreHistory;
    const backupHistoryState = [...backupHistory, res];
    return { backupHistoryState };
  }
);
//** backup && restore thunk ends  */

//***************************************************************************/
//***************************************************************************/

//** restore thunk starts  */
export const getAllRestoreHistory = createAsyncThunk(
  'get-all-restore-history',
  async () => {
    const res = await GetAllRestoreHistory();
    return res;
  }
);

export const getRestoreHistoryById = createAsyncThunk(
  'get-backup-restore-byId',
  async (id) => {
    const res = await GetRestoreHistoryById(id);
    return res;
  }
);

export const createRestoreHistory = createAsyncThunk(
  'create-restore-history',
  async (data, { getState }) => {
    const res = await CreateRestoreHistory(data);
    const { restoreHistory } = getState().backupRestoreHistory;
    const restoreHistoryState = [...restoreHistory, res];
    return { restoreHistoryState };
  }
);

//**  restore thunk ends  */

//***************************************************************************/
//***************************************************************************/

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Tuesday 14 March 2023 18:49:25
*/
