/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Monday 20 December 2021 00:00:00
*/

import { lazy } from 'react';
import Loadable from 'app/components/Loadable/Loadable';

const Dashboard = Loadable(lazy(() => import('./Dashboard')));
//const Analytics = Loadable(lazy(() => import('./Analytics')));
// const Analytics = Loadable(lazy(() => import('./Analytics3')));

// const InventoryManagement = Loadable(
// lazy(() => import('./InventoryManagement'))
// );

export const dashboardRoutes = [
  {
    path: '/dashboard',
    element: <Dashboard />,
  },
  // {
  //   path: '/dashboard3',
  //   element: <Analytics />,
  // },
];

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Monday 18 July 2022 18:20:35
*/
