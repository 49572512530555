/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Sunday 12 December 2021 00:00:00
*/

import { createSlice } from '@reduxjs/toolkit';

import {
  getAllParticipantsProfile,
  createParticipantProfile,
  updateParticipantProfileById,
  deleteParticipantProfileById,
} from '../thunk/participantsProfile.Thunk';

const initialState = {
  participantsProfile: [],
};

export const participantsProfileSlice = createSlice({
  name: 'participantsProfile',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(
      getAllParticipantsProfile.fulfilled,
      (state, { payload }) => {
        state.participantsProfile = payload;
      }
    );

    builder.addCase(
      createParticipantProfile.fulfilled,
      (state, { payload }) => {
        const { participantsProfileState } = payload;
        state.participantsProfile = participantsProfileState;
      }
    );

    builder.addCase(
      updateParticipantProfileById.fulfilled,
      (state, { payload }) => {
        const { participantsProfileState } = payload;
        state.participantsProfile = participantsProfileState;
      }
    );

    builder.addCase(
      deleteParticipantProfileById.fulfilled,
      (state, { payload }) => {
        const { participantsProfileState } = payload;
        state.participantsProfile = participantsProfileState;
      }
    );
  },
});

export default participantsProfileSlice.reducer;

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Monday 22 May 2023 17:29:42
*/
