/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Wednesday 03 July 2024 19:10:00
*/

import { createSlice } from '@reduxjs/toolkit';

import {
  getAllProjectApplications,
  createProjectApplication,
  updateProjectApplicationById,
  deleteProjectApplicationById,
} from '../thunk/projectApplications.Thunk';

const initialState = {
  projectApplications: [],
};

export const projectApplicationsSlice = createSlice({
  name: 'projectApplications',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(
      getAllProjectApplications.fulfilled,
      (state, { payload }) => {
        state.projectApplications = payload;
      }
    );

    builder.addCase(
      createProjectApplication.fulfilled,
      (state, { payload }) => {
        const { projectApplicationsState } = payload;
        state.projectApplications = projectApplicationsState;
      }
    );

    builder.addCase(
      updateProjectApplicationById.fulfilled,
      (state, { payload }) => {
        const { projectApplicationsState } = payload;
        state.projectApplications = projectApplicationsState;
      }
    );

    builder.addCase(
      deleteProjectApplicationById.fulfilled,
      (state, { payload }) => {
        const { projectApplicationsState } = payload;
        state.projectApplications = projectApplicationsState;
      }
    );
  },
});

export default projectApplicationsSlice.reducer;

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Wednesday 03 July 2024 19:15:00
*/
