/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Thursday 07 July 2022 16:48:19
*/

import { lazy } from 'react';
import Loadable from 'app/components/Loadable/Loadable';

import useAuth from 'app/hooks/useAuth';
import { Navigate } from 'react-router-dom';

export const RouteGuard = ({ children }) => {
  const { adminVerified: isVerified } = useAuth();
  return <>{isVerified ? children : <Navigate to="/manage-users/users" />}</>;
};

//** my account routes components*/
//***************************************************************************/
//***************************************************************************/

const MyAccount = Loadable(lazy(() => import('./my-account/MyAccount')));

const ChangePassword = Loadable(
  lazy(() => import('./my-account/change-password/ChangePasswordOverview'))
);

const Enable2FA = Loadable(
  lazy(() => import('./my-account/enable-2fa/Enable2FaOverview'))
);

const Disable2FA = Loadable(
  lazy(() => import('./my-account/disable-2fa/Disable2FAOverview'))
);

//***************************************************************************/
//***************************************************************************/

const manageUsersRoutes = [
  //** my account routes */
  {
    path: '/my-account/',
    element: <MyAccount />,
  },
  {
    path: '/my-account/change-password',
    element: <ChangePassword />,
  },
  {
    path: '/my-account/enable-2fa',
    element: <Enable2FA />,
  },
  {
    path: '/my-account/disable-2fa',
    element: <Disable2FA />,
  },
];

export default manageUsersRoutes;

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Thursday 14 July 2022 16:10:05
*/
