/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Sunday 23 April 2023 16:32:26
*/

import { createAsyncThunk } from '@reduxjs/toolkit';

//** get research resources */
import {
  GetAllResearchResources,
  GetResearchResourceById,
  CreateResearchResource,
  UpdateResearchResourceById,
  DeleteResearchResourceById,
} from 'app/services/in-house-resources/ResearchResources.services';

import store from 'app/store/store';

//***************************************************************************/
//***************************************************************************/

//** research resource thunk starts  */
export const getAllResearchResources = createAsyncThunk(
  'get-all-research-resources',
  async () => {
    const res = await GetAllResearchResources();
    return res;
  }
);

export const getResearchResourceById = createAsyncThunk(
  'get-research-resource-byId',
  async (id) => {
    const res = await GetResearchResourceById(id);
    return res;
  }
);

export const createResearchResource = createAsyncThunk(
  'create-research-resource',
  async (data, { getState }) => {
    const res = await CreateResearchResource(data);
    const { researchResources } = getState().researchResources;
    const researchResourcesState = [...researchResources, res];
    return { researchResourcesState };
  }
);

export const updateResearchResourceById = createAsyncThunk(
  'update-research-resource-byId',
  async ({ linkId, ...rest }) => {
    const res = await UpdateResearchResourceById(linkId, rest);
    const { researchResources } = store.getState().researchResources;
    const filteredResearchResources = researchResources.filter(
      (t) => t.linkId !== linkId
    );
    const researchResourcesState = [...filteredResearchResources, res];
    return { researchResourcesState };
  }
);

export const deleteResearchResourceById = createAsyncThunk(
  'delete-research-resource-byId',
  async (linkId) => {
    await DeleteResearchResourceById(linkId);
    const { researchResources } = store.getState().researchResources;
    const filteredResearchResources = researchResources.filter(
      (t) => t.linkId !== linkId
    );
    const researchResourcesState = [...filteredResearchResources];
    return { researchResourcesState };
  }
);

//** research resource thunk ends  */

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Monday 24 April 2023 10:36:51
*/
