/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Sunday 16 January 2022 16:08:04
*/

import http from '../http';

//** get all expenses account trxns */
export const GetAllExpensesAccountTrxns = async () => {
  try {
    const res = await http.get('/trxns/expenses-trxns');
    return res.data.data;
  } catch (err) {}
};

//** get expenses account trxn by id */
export const GetExpensesAccountTrxnById = async (id) => {
  try {
    const res = await http.get(`/trxns/expenses-trxns/${id}`);
    return res.data.data;
  } catch (err) {}
};

//** create expenses account trxn */
export const CreateExpensesAccountTrxn = async (data) => {
  try {
    const res = await http.post('/trxns/expenses-trxns', data);    
    return res.data.data;
  } catch (err) {    
    return err;
  }
};

//** update expenses account trxn by id */
export const UpdateExpensesAccountTrxnById = async (id, data) => {
  try {
    const res = await http.patch(`/trxns/expenses-trxns/${id}`, data);
    return res.data.data;
  } catch (err) {
    return err;
  }
};

//** delete expenses account trxn by id */
export const DeleteExpensesAccountTrxnById = async (id) => {
  try {
    const res = await http.delete(`/trxns/expenses-trxns/${id}`);
    return res.data;
  } catch (err) {
    return err;
  }
};

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Saturday 14 January 2023 22:43:02
*/
