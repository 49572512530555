/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Sunday 12 December 2021 00:00:00
*/

import { createSlice } from '@reduxjs/toolkit';

import {
  getAllStaffProfile,
  createStaffProfile,
  updateStaffProfileById,
  deleteStaffProfileById,
} from '../thunk/staffProfile.Thunk';

const initialState = {
  staffProfile: [],
};

export const staffProfileSlice = createSlice({
  name: 'staffProfile',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getAllStaffProfile.fulfilled, (state, { payload }) => {
      state.staffProfile = payload;
    });

    builder.addCase(createStaffProfile.fulfilled, (state, { payload }) => {
      const { staffProfileState } = payload;
      state.staffProfile = staffProfileState;
    });

    builder.addCase(updateStaffProfileById.fulfilled, (state, { payload }) => {
      const { staffProfileState } = payload;
      state.staffProfile = staffProfileState;
    });

    builder.addCase(deleteStaffProfileById.fulfilled, (state, { payload }) => {
      const { staffProfileState } = payload;
      state.staffProfile = staffProfileState;
    });
  },
});

//** action creators are generated for each case reducer function */

export default staffProfileSlice.reducer;

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Monday 22 May 2023 18:04:53
*/
