/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Wednesday 16 November 2022 05:21:42
*/

import http from '../http';

//** get all clients http request */
export const GetAllClients = async () => {
  try {
    const res = await http.get('/clients');
    return res.data.data;
  } catch (err) {
    return err;
  }
};

//** get client by id http request */
export const GetClientById = async (id) => {
  try {
    const res = await http.get(`/clients/${id}`);
    return res.data.data;
  } catch (err) {
    return err;
  }
};

//** create clients http request */
export const CreateClient = async (data) => {
  try {
    const res = await http.post('/clients/', data);
    return res.data.data;
  } catch (err) {
    return err;
  }
};

//** update clients by id http request */
export const UpdateClientById = async (id, data) => {
  const res = await http.patch(`/clients/${id}`, data);
  return res.data.data;
};

//** delete clients http request */
export const DeleteClientById = async (id) => {
  const res = await http.delete(`/clients/${id}`);
  return res.data;
};

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Tuesday 14 March 2023 18:50:30
*/
