/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Thursday 06 January 2022 13:00:00
*/

import { Button } from '@mui/material';
import { styled } from '@mui/system';

export const BtnTeal = styled(Button)(() => ({
  background: '#096469 !important',
  color: '#fff',
  transition: 'all 250ms',
  fontSize: '12px',
  fontWeight: '500',
  '&:hover': {
    background: `rgba(9, 82, 109,1) !important`,
    color: '#fff',
    fallbacks: [{ color: 'white !important' }],
  },
}));

export const BtnWine = styled(Button)(() => ({
  background: '#841d3f !important',
  color: '#fff',
  transition: 'all 250ms',
  fontSize: '12px',
  fontWeight: '500',
  '&:hover': {
    background: `rgba(100, 4, 63, 1) !important`,
    color: '#fff',
  },
}));

export const BtnBlue = styled(Button)(() => ({
  background: '#114976df !important',
  color: '#fff',
  transition: 'all 250ms',
  fontSize: '12px',
  fontWeight: '500',
  '&:hover': {
    background: '#11529f !important',
    color: '#fff',
  },
}));

export const BtnGold = styled(Button)(() => ({
  background: '#ffaf3e !important',
  color: '#000',
  transition: 'all 250ms',
  fontSize: '12px',
  fontWeight: '500',
  '&:hover': {
    background: '#b27a27 !important',
    color: '#000',
  },
}));

export const BtnDark = styled(Button)(() => ({
  background: '#000 !important',
  color: '#fff',
  transition: 'all 250ms',
  fontSize: '12px',
  fontWeight: '500',
  '&:hover': {
    background: `rgba(0, 0, 63, 1) !important`,
    color: '#fff',
  },
}));

export const bgTeal = '#096469';
export const bgWine = '#841d3f';
export const bgBlue = '#1976d2';
export const bgGold = '#ffaf3e';
export const bgDark = '#000000';
export const bgPlain = '#e0e0e0';
export const bgPink = '#a50662';

export const textPrimary = '#1976d2';
export const textSuccess = '#009a00';
export const textDark = '#000';
export const textError = '#ff3d57';

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Friday 07 January 2022 04:19:54
*/
