/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Friday 27 January 2023 06:47:54
*/

import { createSlice } from '@reduxjs/toolkit';

import {
  getAllProjectPanels,
  createProjectPanel,
  updateProjectPanelById,
  deleteProjectPanelById,
  //**********************/
  //**********************/
  getAllProjectSubPanels,
  createProjectSubPanel,
  updateProjectSubPanelById,
  deleteProjectSubPanelById,
  //**********************/
  //**********************/
  getAllSubPanelParticipants,
  createSubPanelParticipant,
  updateSubPanelParticipantById,
  deleteSubPanelParticipantById,
} from '../thunk/projectPanels.Thunk';

const initialState = {
  projectPanels: [],
  projectSubPanels: [],
  subPanelParticipants: [],
};

export const projectPanelsSlice = createSlice({
  name: 'projectPanels',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getAllProjectPanels.fulfilled, (state, { payload }) => {
      state.projectPanels = payload;
    });

    builder.addCase(createProjectPanel.fulfilled, (state, { payload }) => {
      const { projectPanelsState } = payload;
      state.projectPanels = projectPanelsState;
    });

    builder.addCase(updateProjectPanelById.fulfilled, (state, { payload }) => {
      const { projectPanelsState } = payload;
      state.projectPanels = projectPanelsState;
    });

    builder.addCase(deleteProjectPanelById.fulfilled, (state, { payload }) => {
      const { projectPanelsState } = payload;
      state.projectPanels = projectPanelsState;
    });

    //**************************************************************/
    //**************************************************************/
    builder.addCase(getAllProjectSubPanels.fulfilled, (state, { payload }) => {
      state.projectSubPanels = payload;
    });

    builder.addCase(createProjectSubPanel.fulfilled, (state, { payload }) => {
      const { projectSubPanelsState } = payload;
      state.projectSubPanels = projectSubPanelsState;
    });

    builder.addCase(
      updateProjectSubPanelById.fulfilled,
      (state, { payload }) => {
        const { projectSubPanelsState } = payload;
        state.projectSubPanels = projectSubPanelsState;
      }
    );

    builder.addCase(
      deleteProjectSubPanelById.fulfilled,
      (state, { payload }) => {
        const { projectSubPanelsState } = payload;
        state.projectSubPanels = projectSubPanelsState;
      }
    );

    //**************************************************************/
    //**************************************************************/
    builder.addCase(
      getAllSubPanelParticipants.fulfilled,
      (state, { payload }) => {
        state.subPanelParticipants = payload;
      }
    );

    builder.addCase(
      createSubPanelParticipant.fulfilled,
      (state, { payload }) => {
        const { subPanelParticipantsState } = payload;
        state.subPanelParticipants = subPanelParticipantsState;
      }
    );

    builder.addCase(
      updateSubPanelParticipantById.fulfilled,
      (state, { payload }) => {
        const { subPanelParticipantsState } = payload;
        state.subPanelParticipants = subPanelParticipantsState;
      }
    );

    builder.addCase(
      deleteSubPanelParticipantById.fulfilled,
      (state, { payload }) => {
        const { subPanelParticipantsState } = payload;
        state.subPanelParticipants = subPanelParticipantsState;
      }
    );

    //**************************************************************/
    //**************************************************************/
  },
});

export default projectPanelsSlice.reducer;

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Monday 22 May 2023 17:36:45
*/
