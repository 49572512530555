/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Saturday 01 October 2022 23:05:00
*/

import { createAsyncThunk } from '@reduxjs/toolkit';

//** get sms usage history service */
import {
  GetAllSmsUsageHistory,
  GetSmsUsageHistoryById,
  CreateSmsUsageHistory,
} from 'app/services/sms-email-usage/SmsUsageHistory.services';

//** get email usage history service */
import {
  GetAllEmailUsageHistory,
  GetEmailUsageHistoryById,
  CreateEmailUsageHistory,
} from 'app/services/sms-email-usage/EmailUsageHistory.services';

//***************************************************************************/
//***************************************************************************/

//** sms usage history thunk starts  */
export const getAllSmsUsageHistory = createAsyncThunk(
  'get-all-sms-usage-history',
  async () => {
    const res = await GetAllSmsUsageHistory();
    return res;
  }
);

export const getSmsUsageHistoryById = createAsyncThunk(
  'get-sms-usage-history-byId',
  async (id) => {
    const res = await GetSmsUsageHistoryById(id);
    return res;
  }
);

export const createSmsUsageHistory = createAsyncThunk(
  'create-sms-usage-history',
  async (data, { getState }) => {
    const res = await CreateSmsUsageHistory(data);

    //** get sms usage history */
    const { smsUsageHistory } = getState().smsEmailUsageHistory;

    const smsUsageHistoryState = [...smsUsageHistory, res];
    return { smsUsageHistoryState };
  }
);

//** sms usage history thunk ends  */

//***************************************************************************/
//***************************************************************************/
//** email usage history thunk starts  */
export const getAllEmailUsageHistory = createAsyncThunk(
  'get-all-email-usage-history',
  async () => {
    const res = await GetAllEmailUsageHistory();
    return res;
  }
);

export const getEmailUsageHistoryById = createAsyncThunk(
  'get-email-usage-history-byId',
  async (id) => {
    const res = await GetEmailUsageHistoryById(id);
    return res;
  }
);

export const createEmailUsageHistory = createAsyncThunk(
  'create-email-usage-history',
  async (data, { getState }) => {
    const res = await CreateEmailUsageHistory(data);

    //** get email usage history */
    const { emailUsageHistory } = getState().smsEmailUsageHistory;

    const emailUsageHistoryState = [...emailUsageHistory, res];
    return { emailUsageHistoryState };
  }
);

//** email usage history thunk ends  */

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Thursday 29 December 2022 03:25:54
*/
