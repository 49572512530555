/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Friday 27 January 2023 03:23:07
*/

import http from '../http';

//** get all project panels http request */
export const GetAllProjectPanels = async () => {
  try {
    const res = await http.get('/project-panels');
    return res.data.data;
  } catch (err) {}
};

//** get project panels by id http request */
export const GetProjectPanelById = async (id) => {
  try {
    const res = await http.get(`/project-panels/${id}`);
    return res.data.data;
  } catch (err) {
    return err;
  }
};

//** create project panelshttp request */
export const CreateProjectPanel = async (data) => {
  try {
    const res = await http.post('/project-panels', data);
    return res.data.data;
  } catch (err) {
    return err;
  }
};

//** update project panelsby id http request */
export const UpdateProjectPanelById = async (id, data) => {
  const res = await http.patch(`/project-panels/${id}`, data);
  return res.data.data;
};

//** delete project panelshttp request */
export const DeleteProjectPanelById = async (id) => {
  const res = await http.delete(`/project-panels/${id}`);
  return res.data;
};

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Friday 27 January 2023 03:25:26
*/
