/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Wednesday 10 August 2022 05:54:31
*/

import { createSlice } from '@reduxjs/toolkit';

import {
  getAllBackupHistory,
  createBackupHistory,
  //******************/
  //******************/
  getAllRestoreHistory,
  createRestoreHistory,
} from '../thunk/backupRestoreHistory.Thunk';

const initialState = {
  backupHistory: [],
  restoreHistory: [],
};

export const backupRestoreSlice = createSlice({
  name: 'backupRestoreHistory',
  initialState,
  extraReducers: (builder) => {
    //** backup history slice */
    builder.addCase(getAllBackupHistory.fulfilled, (state, { payload }) => {
      state.backupHistory = payload;
    });

    builder.addCase(createBackupHistory.fulfilled, (state, { payload }) => {
      const { backupHistoryState } = payload;
      state.backupHistory = backupHistoryState;
    });

    //** restore history slice */
    //********************************************/

    //********************************************/
    //** restore history slice */
    builder.addCase(getAllRestoreHistory.fulfilled, (state, { payload }) => {
      state.restoreHistory = payload;
    });

    builder.addCase(createRestoreHistory.fulfilled, (state, { payload }) => {
      const { restoreHistoryState } = payload;
      state.restoreHistory = restoreHistoryState;
    });
  },
});

export default backupRestoreSlice.reducer;

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Monday 22 May 2023 17:06:18
*/
