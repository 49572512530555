/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Tuesday 19 July 2022 18:33:22
*/

import { lazy } from 'react';
import Loadable from 'app/components/Loadable/Loadable';

const GettingStarted = Loadable(lazy(() => import('./getting-started/GettingStarted2')));

const pagesRoutes = [
  {
    path: '/help-center/getting-started',
    element: <GettingStarted />,
  },
];

export default pagesRoutes;

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Tuesday 19 July 2022 18:33:28
*/
