/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Friday 08 July 2022 00:00:00
*/

import http from '../http';

//** get all admin */
export const GetAllAdmin = async () => {
  try {
    const res = await http.get('/admin');
    return res.data.data;
  } catch (err) {}
};

//** create admin */
export const CreateAdmin = async (data) => {
  try {
    const res = await http.post('/admin', data);
    return res.data.data;
  } catch (err) {
    return err;
  }
};

//** update admin by id */
export const UpdateAdminById = async (id, data) => {
  try {
    const res = await http.patch(`/admin/${id}`, data);
    return res.data.data;
  } catch (err) {
    return err;
  }
};

//** delete admin by id */
export const DeleteAdminById = async (id) => {
  try {
    const res = await http.delete(`/admin/${id}`);
    return res.data;
  } catch (err) {
    return err;
  }
};

//** get auth secret key */
export const GetAuthSecretKey = async () => {
  try {
    const res = await http.get('/auth-secret-key');
    return res.data.data;
  } catch (err) {}
};

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Tuesday 14 March 2023 16:52:05
*/