/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Saturday 01 October 2022 23:02:12
*/

import http from '../http';

//** get all sms usage history */
export const GetAllSmsUsageHistory = async () => {
  try {
    const res = await http.get('/sms-usage-history');
    return res.data.data;
  } catch (err) {}
};

//** get sms usage history by id */
export const GetSmsUsageHistoryById = async (id) => {
  try {
    const res = await http.get(`/sms-usage-history/${id}`);
    return res.data.data;
  } catch (err) {}
};

//** create sms usage history */
export const CreateSmsUsageHistory = async (data) => {
  try {
    const res = await http.post('/sms-usage-history/', data);
    return res.data.data;
  } catch (err) {
    return err;
  }
};

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Saturday 01 October 2022 23:02:42
*/
