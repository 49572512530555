/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Tuesday 20 September 2022 12:06:44
*/

import http from '../http';

//** get all invoice history */
export const GetAllInvoiceHistory = async () => {
  try {
    const res = await http.get('/invoice-history');
    return res.data.data;
  } catch (err) {}
};

//** create invoice history */
export const CreateInvoiceHistory = async (data) => {
  try {
    const res = await http.post('/invoice-history', data);
    return res.data.data;
  } catch (err) {
    return err;
  }
};

//** update invoice history by id */
export const UpdateInvoiceHistoryById = async (id, data) => {
  try {
    const res = await http.patch(`/invoice-history/${id}`, data);
    return res.data;
  } catch (err) {
    return err;
  }
};

//** delete invoice history by id */
export const DeleteInvoiceHistoryById = async (id) => {
  try {
    const res = await http.delete(`/invoice-history/${id}`);
    return res.data;
  } catch (err) {
    return err;
  }
};

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Tuesday 20 September 2022 12:08:35
*/
