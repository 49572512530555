/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Monday 20 December 2021 00:00:00
*/

import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  GetAllParticipantsProfile,
  CreateParticipantProfile,
  UpdateParticipantProfileById,
  DeleteParticipantProfileById,
} from 'app/services/participants/Participants.services';

import store from 'app/store/store';

export const getAllParticipantsProfile = createAsyncThunk(
  'participants-profile/getAll',
  async () => {
    const res = await GetAllParticipantsProfile();
    return res;
  }
);

export const createParticipantProfile = createAsyncThunk(
  'participant-profile/create',
  async (data, { getState }) => {
    const res = await CreateParticipantProfile(data);
    const { participantsProfile } = getState().participantsProfile;
    const participantsProfileState = [...participantsProfile, res];
    return { participantsProfileState };
  }
);

export const updateParticipantProfileById = createAsyncThunk(
  'participant-profile/update',
  async ({ participantId, ...data }) => {
    const res = await UpdateParticipantProfileById(participantId, data);
    const { participantsProfile } = store.getState().participantsProfile;
    const filteredParticipantsProfile = participantsProfile?.filter(
      (t) => t.participantId !== participantId
    );
    const participantsProfileState = [res, ...filteredParticipantsProfile];
    return { participantsProfileState };
  }
);

export const deleteParticipantProfileById = createAsyncThunk(
  'participant-profile/delete',
  async (participantId) => {
    await DeleteParticipantProfileById(participantId);
    const { participantsProfile } = store.getState().participantsProfile;
    const participantsProfileState = participantsProfile?.filter(
      (t) => t.participantsId !== participantId
    );

    return { participantsProfileState };
  }
);

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Tuesday 17 January 2023 22:32:45
*/
