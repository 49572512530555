/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Sunday 09 October 2022 12:15:25
*/

import http from '../http';

//!!NOTE:: Staff is called as Employees from the backend
//** get all staff http request */
export const GetAllStaffProfile = async () => {
  try {
    const res = await http.get('/employees');
    sessionStorage.setItem('serverCheck', 'Server online');
    return res.data.data;
  } catch (err) {
    sessionStorage.setItem('serverCheck', 'Server offline');
  }
};

//** get staff by id http request */
export const GetStaffProfileById = async (id) => {
  try {
    const res = await http.get(`/employees/${id}`);
    return res.data.data;
  } catch (err) {
    return err;
  }
};

//** create staff http request */
export const CreateStaffProfile = async (data) => {
  try {
    const res = await http.post('/employees/', data);

    sessionStorage.setItem('status', res.status);
    sessionStorage.setItem('message', res.data.message);
    return res.data.data;
  } catch (err) {
    return err;
  }
};

//** update staff by id http request */
export const UpdateStaffProfileById = async (id, data) => {
  const res = await http.patch(`/employees/${id}`, data);
  return res.data.data;
};

//** delete staff http request */
export const DeleteStaffProfileById = async (id) => {
  const res = await http.delete(`/employees/${id}`);
  return res.data;
};

//!!NOTE:: Staff is called as Employees from the backend

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Wednesday 15 March 2023 16:00:32
*/
