/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Monday 27 February 2023 13:13:28
*/

import { createSlice } from '@reduxjs/toolkit';

import {
  getAllFileStorageHistory,
  createFileStorageHistory,
  deleteFileStorageHistoryById,
} from '../thunk/fileStorageHistory.Thunk';

const initialState = {
  fileStorageHistory: [],
};

export const fileStorageHistorySlice = createSlice({
  name: 'fileStorageHistory',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(
      getAllFileStorageHistory.fulfilled,
      (state, { payload }) => {
        state.fileStorageHistory = payload;
      }
    );

    builder.addCase(
      createFileStorageHistory.fulfilled,
      (state, { payload }) => {
        const { fileStorageHistoryState } = payload;
        state.fileStorageHistory = fileStorageHistoryState;
      }
    );

    builder.addCase(
      deleteFileStorageHistoryById.fulfilled,
      (state, { payload }) => {
        const { fileStorageHistoryState } = payload;
        state.fileStorageHistory = fileStorageHistoryState;
      }
    );
  },
});

export default fileStorageHistorySlice.reducer;

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Monday 22 May 2023 17:20:07
*/
