import '../fake-db';
import React from 'react';
import { Store } from './redux/Store';
import { Provider } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import { AuthProvider } from 'app/contexts/JWTAuthContext';
import { SettingsProvider } from 'app/contexts/SettingsContext';
import { MatxTheme } from './components';
import routes from './routes/routes';
import store from './store/store';

import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';

const persistor = persistStore(store);

const App = () => {
  const content = useRoutes(routes);

  return (
    <Provider store={Store}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <SettingsProvider>
            <MatxTheme>
              <AuthProvider>{content}</AuthProvider>
            </MatxTheme>
          </SettingsProvider>
        </PersistGate>
      </Provider>
    </Provider>
  );
};

export default App;
