/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Thursday 07 July 2022 16:48:19
*/

import { lazy } from 'react';
import Loadable from 'app/components/Loadable/Loadable';

import { authRoles } from 'app/auth/authRoles';

import useAuth from 'app/hooks/useAuth';
import { Navigate } from 'react-router-dom';

const RouteGuard = ({ children }) => {
  const { adminVerified: isVerified } = useAuth();
  return <>{isVerified ? children : <Navigate to="/manage-users/users" />}</>;
};

//** manage users routes components*/
//***************************************************************************/
//***************************************************************************/

const ManageUsers = Loadable(lazy(() => import('./manage-users/ManageUsers')));

const ManageUsersAccount = Loadable(
  lazy(() => import('./manage-users/manage-users/ManageUsersOverview'))
);

const UsersSettings = Loadable(
  lazy(() => import('./manage-users/users-settings/UsersSettingsOverview'))
);

const DefaultPassword = Loadable(
  lazy(() => import('./manage-users/default-password/DefaultPasswordOverview'))
);

//***************************************************************************/
//***************************************************************************/

const manageUsersRoutes = [
  //** manage user routes  */
  {
    path: '/manage-users/users',
    element: <ManageUsers />,
    auth: authRoles.level4,
  },
  {
    path: '/manage-users/users-account',
    element: (
      <RouteGuard>
        <ManageUsersAccount />
      </RouteGuard>
    ),
  },
  {
    path: '/manage-users/users-settings',
    element: (
      <RouteGuard>
        <UsersSettings />
      </RouteGuard>
    ),
  },
  {
    path: '/manage-users/default-password',
    element: (
      <RouteGuard>
        <DefaultPassword />
      </RouteGuard>
    ),
  },
];

export default manageUsersRoutes;

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Monday 03 October 2022 20:38:14
*/
