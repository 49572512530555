import { combineReducers } from 'redux';
//import ScrumBoardReducer from './ScrumBoardReducer';
//import NotificationReducer from './NotificationReducer';
//import EcommerceReducer from './EcommerceReducer';
import NavigationReducer from './NavigationReducer';

const RootReducer = combineReducers({
  navigations: NavigationReducer,
  //notifications: NotificationReducer,
  //scrumboard: ScrumBoardReducer,
  //ecommerce: EcommerceReducer,
});

export default RootReducer;
