/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Tuesday 29 November 2022 03:26:05
*/

import http from '../http';

//** get all projects http request */
export const GetAllProjects = async () => {
  try {
    const res = await http.get('/projects');
    return res.data.data;
  } catch (err) {}
};

//** get project by id http request */
export const GetProjectById = async (id) => {
  try {
    const res = await http.get(`/projects/${id}`);
    return res.data.data;
  } catch (err) {
    return err;
  }
};

//** create project http request */
export const CreateProject = async (data) => {
  try {
    const res = await http.post('/projects', data);
    return res.data.data;
  } catch (err) {
    return err;
  }
};

//** update project by id http request */
export const UpdateProjectById = async (id, data) => {
  const res = await http.patch(`/projects/${id}`, data);
  return res.data.data;
};

//** delete project http request */
export const DeleteProjectById = async (id) => {
  const res = await http.delete(`/projects/${id}`);
  return res.data;
};

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Tuesday 29 November 2022 03:28:49
*/
