/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Wednesday 30 November 2022 13:01:41
*/

import { lazy } from 'react';
import Loadable from 'app/components/Loadable/Loadable';

//** projects routes components*/
//***************************************************************************/
//***************************************************************************/

const Projects = Loadable(lazy(() => import('./Projects')));

const ClientsOverview = Loadable(
  lazy(() => import('./clients-view/ProjectsOverview'))
);

const ProjectListOverview = Loadable(
  lazy(() => import('./list-view/ProjectsList'))
);

const ProjectDetails1 = Loadable(
  lazy(() => import('./clients-view/ProjectDetailsOverview'))
);

const ProjectDetails2 = Loadable(
  lazy(() => import('./list-view/ProjectDetailsOverview'))
);

const ProjectsSettings = Loadable(
  lazy(() => import('./projects-settings/ProjectsSettingsOverview'))
);

const ProjectsApplicationSettings = Loadable(
  lazy(() =>
    import('./project-applications-setting/ProjectApplicationsOverview')
  )
);

//** projects routes components */
//***************************************************************************/
//***************************************************************************/

const projectsDatabaseRoutes = [
  //** projects routes */
  {
    path: '/projects',
    element: <Projects />,
  },
  {
    path: '/projects/clients-view',
    element: <ClientsOverview />,
  },
  {
    path: '/projects/list-view',
    element: <ProjectListOverview />,
  },
  {
    path: '/projects/project-details/:id',
    element: <ProjectDetails1 />,
  },
  {
    path: '/projects/project-details-by-list/:id',
    element: <ProjectDetails2 />,
  },
  {
    path: '/projects/projects-settings',
    element: <ProjectsSettings />,
  },
  {
    path: '/projects/application-settings',
    element: <ProjectsApplicationSettings />,
  },
];

export default projectsDatabaseRoutes;

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Friday 09 December 2022 23:43:05
*/
