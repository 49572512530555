/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Monday 20 December 2021 12:00:00
*/

export const productLine = 'App Suite';
export const productName = 'App Suite';
export const version = '2.0.0.0';
export const appDeveloper = 'Mojo Team';
export const appTag1 = `Strategy,`;
export const appTag2 = ` Business, Innovation`;

export const appWebPage = 'https://www.mojo-africa.com';
export const tectonicsLogo = `https://ucarecdn.com/b3f5cd9b-830a-455e-ba56-e72b12cb569f/logo192.png`;

export const databaseName = 'MojoAppSuite';

export const employeeTicker = 'EMP';
export const invoiceHistoryTicker = 'INV';

export const clientInformation = {
  clientId: 'MOJO-NG-2210-1024',
  clientNanoId: '74221af4-f158-4ec7-a354-14b592e2a1b2',
  licenseId: 'MOJO-1024',
  licenseNanoId: '2eff0452-e411-4290-995d-55d9def94f58',
  name: 'Mojo Africa',
  businessName: 'Mojo Africa',
  businessNameLocation: 'Mojo Africa',
  shortCode: 'Mojo',
  address: 'Africa, Asia, Europe',
  email: 'info@mojo-africa.com',
  phone: '+31-6-15133872',
  industry: 'Research',
  location: 'Worldwide',
  state: 'Lagos',
  country: 'Nigeria',
  activationDate: '04 Oct 2022',
  website: 'https://www.mojo-africa.com',
};

export const reportFooter1 = `Generated With Mojo App Suite -- www.mojo-africa.com`;
export const reportFooter2 = `${new Date()}`;
export const reportFooter3 = `Licensed to ${clientInformation?.businessName}`;

export const inputVariant = 'standard';
export const alertDisplay = 'none';

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Monday 20 February 2023 05:47:17
*/
