/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Monday 17 January 2022 01:34:54
*/

import { createAsyncThunk } from '@reduxjs/toolkit';

//** expenses account services */
import {
  GetAllExpensesAccount,
  GetExpensesAccountById,
  CreateExpensesAccount,
  UpdateExpensesAccountById,
  DeleteExpensesAccountById,
} from 'app/services/account-types/ExpensesAccount.services';

//** expenses account transactions */
import {
  GetAllExpensesAccountTrxns,
  CreateExpensesAccountTrxn,
  UpdateExpensesAccountTrxnById,
  DeleteExpensesAccountTrxnById,
} from 'app/services/transactions/ExpensesAccountTrxns.services';

import store from 'app/store/store';

//** expenses account thunk starts  */
export const getAllExpensesAccount = createAsyncThunk(
  'expenses/get-exp-acc',
  async () => {
    const res = await GetAllExpensesAccount();
    return res;
  }
);

export const createExpensesAccount = createAsyncThunk(
  'expenses/create-exp-acc',
  async (data, { getState }) => {
    const res = await CreateExpensesAccount(data);
    const { expensesAccount } = getState().expenses;
    const expensesAccountState = [...expensesAccount, res];
    return { expensesAccountState };
  }
);

export const updateExpensesAccountById = createAsyncThunk(
  'expenses/update-exp-acc-byId',
  async ({ accountId, ...rest }) => {
    const res = await UpdateExpensesAccountById(accountId, rest);

    //**get existing trxns on acc from session storage */
    let trxnsArray = sessionStorage.getItem('expAccTrxns');

    //** parse trxn => JSON */
    const expAccTrxns = JSON.parse(trxnsArray);

    //** spread exp acc trxns with response from api */
    const expensesAcc = { ...res, expAccTrxns };

    //** get data from state */
    const { expensesAccount } = store.getState().expenses;

    //** filter to remove previous name */
    const filteresExpAcc = expensesAccount.filter(
      (t) => t.accountId !== accountId
    );

    //** pass updated acc name && trxns to state  */
    const expensesAccountState = [...filteresExpAcc, expensesAcc];
    return { expensesAccountState };
  }
);

export const deleteExpensesAccountById = createAsyncThunk(
  'expenses/delete-exp-acc-byId',
  async (accountId) => {
    await DeleteExpensesAccountById(accountId);

    //** get data from state */
    const { expensesAccount } = store.getState().expenses;

    const expensesAccountState = expensesAccount.filter(
      (t) => t.accountId !== accountId
    );

    return { expensesAccountState };
  }
);
//** expenses account thunk ends  */

//***********************************************************************************/
//***********************************************************************************/

//** expenses account trxns thunk starts  */
export const getAllExpensesAccountTrxns = createAsyncThunk(
  'expenses/get-exp-trxns',
  async () => {
    const res = await GetAllExpensesAccountTrxns();
    return res;
  }
);

//! note==> request here is made to exp acc not exp-acc-trxn */
export const getExpensesAccountTrxnById = createAsyncThunk(
  'expenses/get-exp-trxn-byId',
  async (id) => {
    const res = await GetExpensesAccountById(id);
    return res;
  }
);

export const createExpensesAccountTrxn = createAsyncThunk(
  'expenses/create-exp-trxns',
  async (data, { getState, ...rest }) => {
    const res = await CreateExpensesAccountTrxn(data);
    //** get data from state */
    const { expensesAccountTrxns } = getState().expenses;
    const expAccTrxnsState = [...expensesAccountTrxns, res];
    return { expAccTrxnsState };
  }
);

export const updateExpensesAccountTrxnById = createAsyncThunk(
  'expenses/update-exp-trxn-byId',
  async ({ trxnId, ...rest }) => {
    const res = await UpdateExpensesAccountTrxnById(trxnId, rest);
    //** get data from state */
    const { expensesAccountTrxns } = store.getState().expenses;

    const filteredExpAccTrxns = expensesAccountTrxns.filter(
      (t) => t.trxnId !== trxnId
    );
    const expAccTrxnsState = [...filteredExpAccTrxns, res];
    return { expAccTrxnsState };
  }
);

export const deleteExpensesAccountTrxnById = createAsyncThunk(
  'expenses/delete-exp-trxn-byId',
  async (trxnId) => {
    await DeleteExpensesAccountTrxnById(trxnId);
    //** get data from state */
    const { expensesAccountTrxns } = store.getState().expenses;

    const expAccTrxnsState = expensesAccountTrxns.filter(
      (t) => t.trxnId !== trxnId
    );

    return { expAccTrxnsState };
  }
);

//** expenses account thunk ends  */

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Wednesday 19 April 2023 17:38:06
*/
