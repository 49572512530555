/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Monday 20 December 2021 00:00:00
*/

import { useNavigate } from 'react-router-dom';
import NotFoundImage from 'app/assets/images/app-images/404.png';
import { H1 } from 'app/components/Typography.js';
import { BtnTeal } from 'app/components/CustomComponents/Buttons';

import {
  JustifyBox2 as JustifyBox,
  IMG,
  NotFoundRoot,
} from 'app/views/StyledComponents';

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <NotFoundRoot>
      <JustifyBox>
        <IMG sx={{ mt: -30 }} src={NotFoundImage} alt="Page Not Found" />
        <H1 sx={{ mt: -2, mb: 2 }}>404.</H1>
        <H1 sx={{ mt: -2, mb: 2, fontFamily: 'Yatra One' }}>
          Page Not Found...
        </H1>

        <BtnTeal
          variant="contained"
          sx={{ textTransform: 'capitalize' }}
          onClick={() => navigate(-1)}
        >
          Back
        </BtnTeal>
      </JustifyBox>
    </NotFoundRoot>
  );
};

export default NotFound;

/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Monday 11 July 2022 21:06:08
*/
