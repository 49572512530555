/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Tuesday 29 November 2022 03:55:37
*/

import { createSlice } from '@reduxjs/toolkit';

import {
  getAllProjects,
  createProject,
  updateProjectById,
  deleteProjectById,
} from '../thunk/projects.Thunk';

const initialState = {
  projects: [],
};

export const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getAllProjects.fulfilled, (state, { payload }) => {
      state.projects = payload;
    });

    builder.addCase(createProject.fulfilled, (state, { payload }) => {
      const { projectsState } = payload;
      state.projects = projectsState;
    });

    builder.addCase(updateProjectById.fulfilled, (state, { payload }) => {
      const { projectsState } = payload;
      state.projects = projectsState;
    });

    builder.addCase(deleteProjectById.fulfilled, (state, { payload }) => {
      const { projectsState } = payload;
      state.projects = projectsState;
    });
  },
});

export default projectsSlice.reducer;

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Monday 22 May 2023 17:41:01
*/
