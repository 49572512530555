/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Saturday 01 October 2022 23:02:12
*/

import http from '../http';

//** get all email usage history */
export const GetAllEmailUsageHistory = async () => {
  try {
    const res = await http.get('/email-usage-history');
    return res.data.data;
  } catch (err) {}
};

//** get email usage history by id */
export const GetEmailUsageHistoryById = async (id) => {
  try {
    const res = await http.get(`/email-usage-history/${id}`);
    return res.data.data;
  } catch (err) {}
};

//** create email usage history */
export const CreateEmailUsageHistory = async (data) => {
  try {
    const res = await http.post('/email-usage-history/', data);
    return res.data.data;
  } catch (err) {
    return err;
  }
};

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Saturday 01 October 2022 23:03:33
*/
