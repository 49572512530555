import { Button, Icon } from '@mui/material';

const DownloadButton = ({ propStyle, handleAddProduct }) => {
  return (
    <div>
      <Button
        sx={propStyle}
        variant="contained"
        color="primary"
        onClick={handleAddProduct}
      >
        <Icon sx={{ mr: 1 }} fontSize="small">
          download
        </Icon>
        Download
      </Button>
    </div>
  );
};

export default DownloadButton;
