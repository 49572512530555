/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Sunday 23 April 2023 16:45:15
*/

import { createSlice } from '@reduxjs/toolkit';

import {
  getAllTrainingResources,
  createTrainingResource,
  updateTrainingResourceById,
  deleteTrainingResourceById,
} from '../thunk/trainingResources.Thunk';

const initialState = {
  trainingResources: [],
};

export const trainingResourceSlice = createSlice({
  name: 'trainingResources',
  initialState,
  extraReducers: (builder) => {
    //** training resources slice */
    builder.addCase(getAllTrainingResources.fulfilled, (state, { payload }) => {
      state.trainingResources = payload;
    });

    builder.addCase(createTrainingResource.fulfilled, (state, { payload }) => {
      const { trainingResourcesState } = payload;
      state.trainingResources = trainingResourcesState;
    });

    builder.addCase(
      updateTrainingResourceById.fulfilled,
      (state, { payload }) => {
        const { trainingResourcesState } = payload;
        state.trainingResources = trainingResourcesState;
      }
    );

    builder.addCase(
      deleteTrainingResourceById.fulfilled,
      (state, { payload }) => {
        const { trainingResourcesState } = payload;
        state.trainingResources = trainingResourcesState;
      }
    );
  },
});

export default trainingResourceSlice.reducer;

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Monday 22 May 2023 18:07:24
*/
