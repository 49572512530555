/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Sunday 23 April 2023 16:32:26
*/

import { createAsyncThunk } from '@reduxjs/toolkit';

//** get training resources */
import {
  GetAllTrainingResources,
  GetTrainingResourceById,
  CreateTrainingResource,
  UpdateTrainingResourceById,
  DeleteTrainingResourceById,
} from 'app/services/in-house-resources/TrainingResources.services';

import store from 'app/store/store';

//***************************************************************************/
//***************************************************************************/

//** training resource thunk starts  */
export const getAllTrainingResources = createAsyncThunk(
  'get-all-training-resource',
  async () => {
    const res = await GetAllTrainingResources();
    return res;
  }
);

export const getTrainingResourceById = createAsyncThunk(
  'get-training-resource-byId',
  async (id) => {
    const res = await GetTrainingResourceById(id);
    return res;
  }
);

export const createTrainingResource = createAsyncThunk(
  'create-training-resource',
  async (data, { getState }) => {
    const res = await CreateTrainingResource(data);
    const { trainingResources } = getState().trainingResources;
    const trainingResourcesState = [...trainingResources, res];
    return { trainingResourcesState };
  }
);

export const updateTrainingResourceById = createAsyncThunk(
  'update-training-resource-byId',
  async ({ linkId, ...rest }) => {
    const res = await UpdateTrainingResourceById(linkId, rest);
    const { trainingResources } = store.getState().trainingResources;
    const filteredTrainingResources = trainingResources.filter(
      (t) => t.linkId !== linkId
    );
    const trainingResourcesState = [...filteredTrainingResources, res];
    return { trainingResourcesState };
  }
);

export const deleteTrainingResourceById = createAsyncThunk(
  'delete-training-resource-byId',
  async (linkId) => {
    await DeleteTrainingResourceById(linkId);
    const { trainingResources } = store.getState().trainingResources;
    const filteredTrainingResources = trainingResources.filter(
      (t) => t.linkId !== linkId
    );
    const trainingResourcesState = [...filteredTrainingResources];
    return { trainingResourcesState };
  }
);

//** training resource thunk ends  */

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Monday 24 April 2023 10:47:39
*/
