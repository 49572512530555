/*
//** Code Signature
//** Name: Quimby africana
//** Created on:  Monday 20 December 2021 00:00:00
*/

import Redirect from 'app/auth/Redirect';
import AuthGuard from 'app/auth/AuthGuard';
import NotFound from 'app/views/sessions/NotFound';
import { dashboardRoutes } from 'app/views/dashboard/Dashboard.routes';
import sessionRoutes from 'app/views/sessions/session.Routes';
import MatxLayout from 'app/components/MatxLayout/MatxLayout';

import helpCenterRoutes from 'app/views/help-center/helpCenter.routes';
import staffRoutes from 'app/views/staff-database/staffDatabase.routes';
import netizensAndCitizensRoutes from 'app/views/netizens-citizens/netizensAndCitizens.routes';
import clientsDatabaseRoute from 'app/views/clients-database/clientDatabase.routes';
import projectsDatabaseRoutes from 'app/views/projects-database/projectsDatabase.routes';
import projectPanelsRoutes from 'app/views/project-panels/projectPanels.routes';

import manageUsersRoutes from 'app/views/manage-users/manageUsers.routes';
import myAccountRoutes from 'app/views/my-account/myAccount.routes';
import dataStudioRoutes from 'app/views/data-studio/data-studio.routes';
import aboutRoutes from 'app/views/about/about.routes';
import smsEmailRoutes from 'app/views/sms-email/sms-email.routes';
import cloudStorageRoutes from 'app/views/cloud-storage/cloudStorage.routes';

import expensesTrackersRoutes from 'app/views/ledgers/ledgers.routes';
import inHouseResourcesRoutes from 'app/views/in-house-resources/inHouseResources.routes';

const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      ...dashboardRoutes,
      ...staffRoutes,
      ...netizensAndCitizensRoutes,
      ...clientsDatabaseRoute,
      ...projectsDatabaseRoutes,
      ...projectPanelsRoutes,
      ...helpCenterRoutes,
      ...manageUsersRoutes,
      ...myAccountRoutes,
      ...dataStudioRoutes,
      ...aboutRoutes,
      ...smsEmailRoutes,
      ...cloudStorageRoutes,
      ...expensesTrackersRoutes,
      ...inHouseResourcesRoutes,
    ],
  },
  ...sessionRoutes,
  { path: '/', element: <Redirect /> },
  { path: '*', element: <NotFound /> },
];

export default routes;

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Sunday 23 April 2023 19:12:27
*/
