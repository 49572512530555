/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Wednesday 15 December 2021 00:00:00
*/

import axios from 'axios';
import jwtDecode from 'jwt-decode';

//** server port */
// export const PORT = '7310';
export const PORT = '7217';

//** base url */
//export const baseURL = `http://localhost:${PORT}/api/v1`;

export const baseURL =
  'https://9hqq9gemte.execute-api.eu-central-1.amazonaws.com/api/v1/';

//** axios base settings */
const instance = axios.create({
  baseURL,
});

//** token && decoded token variables */
let token = '',
  decodedToken = '';

//** token from session storage */
if (sessionStorage.getItem('token')) {
  token = sessionStorage.getItem('token');

  //** decode token to get username */
  decodedToken = jwtDecode(token);
}

//** alter & modify after instance has been created */
instance.defaults.headers.post['Content-Type'] = 'application/json';
instance.defaults.headers.common['Authorization'] = token;

//** setting username on headers */
instance.defaults.headers.common['username'] = decodedToken?.username;

export default instance;

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Monday 03 July 2024 23:54:20
*/
