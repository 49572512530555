/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Wednesday 16 November 2022 05:31:27
*/

import { createAsyncThunk } from '@reduxjs/toolkit';

//** import client services */
import {
  GetAllClients,
  CreateClient,
  UpdateClientById,
  DeleteClientById,
} from '../../services/clients/clients.services';

import {
  GetAllClientsRep,
  CreateClientRep,
  UpdateClientRepById,
  DeleteClientRepById,
} from '../../services/clients/clientsRep.services';

import store from 'app/store/store';

//***************************************************************************/
//***************************************************************************/
//** clients thunk starts  */
export const getAllClients = createAsyncThunk('get-all-clients', async () => {
  const res = await GetAllClients();
  return res;
});

export const createClient = createAsyncThunk(
  'create-client',
  async (data, { getState }) => {
    const res = await CreateClient(data);
    const { clients } = getState().clients;
    const clientsState = [...clients, res];
    return { clientsState };
  }
);

export const updateClientById = createAsyncThunk(
  'update-client-byId',
  async ({ clientId, ...rest }) => {
    const res = await UpdateClientById(clientId, rest);
    const { clients } = store.getState().clients;

    const clientsRep = JSON.parse(sessionStorage.getItem('clientsRep'));
    const projects = JSON.parse(sessionStorage.getItem('projects'));

    const updatedClient = {
      ...res,
      clientsRep,
      projects,
    };

    const filteredClients = clients?.filter((t) => t.clientId !== clientId);
    const clientsState = [updatedClient, ...filteredClients];
    return { clientsState };
  }
);

export const deleteClientById = createAsyncThunk(
  'delete-client-byId',
  async (clientId) => {
    await DeleteClientById(clientId);
    const { clients } = store.getState().clients;
    const clientsState = clients?.filter((t) => t.clientId !== clientId);
    return { clientsState };
  }
);

//** client thunk ends  */

//***************************************************************************/
//***************************************************************************/

//** clients rep thunk starts  */
export const getAllClientsRep = createAsyncThunk(
  'get-all-clients-rep',
  async () => {
    const res = await GetAllClientsRep();
    return res;
  }
);

export const createClientRep = createAsyncThunk(
  'create-client-rep',
  async (data, { getState }) => {
    const res = await CreateClientRep(data);
    const { clientsRep } = getState().clients;
    const clientsRepState = [...clientsRep, res];
    return { clientsRepState };
  }
);

export const updateClientRepById = createAsyncThunk(
  'update-client-rep-byId',
  async ({ clientRepId, ...rest }) => {
    const res = await UpdateClientRepById(clientRepId, rest);
    const { clientsRep } = store.getState().clients;
    const filteredClientsRep = clientsRep?.filter(
      (t) => t.clientRepId !== clientRepId
    );

    const clientsRepState = [res, ...filteredClientsRep];
    return { clientsRepState };
  }
);

export const deleteClientRepById = createAsyncThunk(
  'delete-client-rep-byId',
  async (clientRepId) => {
    await DeleteClientRepById(clientRepId);
    const { clientsRep } = store.getState().clients;
    const clientsRepState = clientsRep?.filter(
      (t) => t.clientRepId !== clientRepId
    );

    return { clientsRepState };
  }
);

//** clients rep thunk ends  */

//***************************************************************************/

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Wednesday 15 March 2023 12:12:27
*/
