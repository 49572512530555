/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Monday 09 January 2023 15:55:46
*/

import { lazy } from 'react';
import Loadable from 'app/components/Loadable/Loadable';

const CloudStorage = Loadable(
  lazy(() => import('./cloud-storage/CloudStorage'))
);

const CloudStorageOverview = Loadable(
  lazy(() => import('./cloud-storage/cloud-drive/CloudDriveOverview'))
);

const cloudStorageRoutes = [
  { path: '/cloud-storage', element: <CloudStorage /> },
  { path: '/cloud-storage/cloud-drive', element: <CloudStorageOverview /> },
];

export default cloudStorageRoutes;

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Sunday 28 May 2023 23:36:03
*/
