/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Tuesday 29 November 2022 03:37:29
*/

import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  GetAllProjects,
  CreateProject,
  UpdateProjectById,
  DeleteProjectById,
} from 'app/services/projects/Projects.services';

import store from 'app/store/store';

export const getAllProjects = createAsyncThunk('projects/getAll', async () => {
  const res = await GetAllProjects();
  return res;
});

export const createProject = createAsyncThunk(
  'projects/create',
  async (data, { getState }) => {
    const res = await CreateProject(data);
    const { projects } = getState().projects;
    const projectsState = [...projects, res];
    return { projectsState };
  }
);

export const updateProjectById = createAsyncThunk(
  'projects/update',
  async ({ projectId, ...data }) => {
    const res = await UpdateProjectById(projectId, data);
    const { projects } = store.getState().projects;
    const filteredProjects = projects?.filter((t) => t.projectId !== projectId);
    const projectsState = [res, ...filteredProjects];
    return { projectsState };
  }
);

export const deleteProjectById = createAsyncThunk(
  'projects/delete',
  async (projectId) => {
    await DeleteProjectById(projectId);
    const { projects } = store.getState().projects;
    const projectsState = projects?.filter((t) => t.projectId !== projectId);
    return { projectsState };
  }
);

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Wednesday 15 March 2023 15:32:04
*/
