/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Monday 20 December 2021 00:00:00
*/

import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist-indexeddb-storage';

import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import hardSet from 'redux-persist/lib/stateReconciler/hardSet';

import { databaseName } from 'app/constants/app.constants';

import staffProfileSlice from 'app/features/slices/staffProfile.Slice';
import participantsProfileSlice from 'app/features/slices/participantsProfile.Slice';
import clientsSlice from 'app/features/slices/clients.Slice';
import projectsSlice from 'app/features/slices/projects.Slice';
import projectApplicationsSlice from 'app/features/slices/projectApplications.Slice';
import projectPanelsSlice from 'app/features/slices/projectPanels.Slice';

import fileStorageHistorySlice from 'app/features/slices/fileStorageHistory.Slice';
import appConfigSlice from 'app/features/slices/appConfig.Slice';
import adminSlice from 'app/features/slices/admin.Slice';
import backupRestoreHistorySlice from 'app/features/slices/backupRestoreHistory.Slice';
import invoiceHistorySlice from 'app/features/slices/invoiceHistory.Slice';
import smsEmailUsageHistorySlice from 'app/features/slices/smsEmailUsageHistory.Slice';
import expensesSlice from 'app/features/slices/expensesAccount.Slice';
import trainingResourcesSlice from 'app/features/slices/trainingResources.Slice';
import researchResourcesSlice from 'app/features/slices/researchResources.Slice';

const reducers = combineReducers({
  admin: adminSlice,
  config: appConfigSlice,
  staffProfile: staffProfileSlice,
  participantsProfile: participantsProfileSlice,
  clients: clientsSlice,
  projects: projectsSlice,
  projectApplications: projectApplicationsSlice,
  projectPanels: projectPanelsSlice,
  fileStorageHistory: fileStorageHistorySlice,
  backupRestoreHistory: backupRestoreHistorySlice,
  invoiceHistory: invoiceHistorySlice,
  smsEmailUsageHistory: smsEmailUsageHistorySlice,
  expenses: expensesSlice,
  trainingResources: trainingResourcesSlice,
  researchResources: researchResourcesSlice,
});

//** redux persist config */
const persistConfig = {
  key: 'root',
  storage: storage(databaseName),
  stateReconciler: hardSet,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk],
});

export default store;

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Wednesday 03 July 2024 19:28:00
*/
