import MojoAfricaLogo from './logo1.png';

const MatxLogo = ({ height, width }) => {
  return (
    <img
      src={MojoAfricaLogo}
      alt="Mojo Africa Logo"
      height={height}
      width={width}
    />
  );
};

export default MatxLogo;
