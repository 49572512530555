/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Monday 17 January 2022 03:00:35
*/

import { createSlice } from '@reduxjs/toolkit';

import {
  getAllExpensesAccount,
  createExpensesAccount,
  updateExpensesAccountById,
  deleteExpensesAccountById,
  getAllExpensesAccountTrxns,
  createExpensesAccountTrxn,
  updateExpensesAccountTrxnById,
  deleteExpensesAccountTrxnById,
} from '../thunk/expensesAccount.Thunk';

const initialState = {
  expensesAccount: [],
  expensesAccountTrxns: [],
};

export const expensesSlice = createSlice({
  name: 'expenses',
  initialState,
  extraReducers: (builder) => {
    //** expenses account slice */
    builder.addCase(getAllExpensesAccount.fulfilled, (state, { payload }) => {
      state.expensesAccount = payload;
    });

    builder.addCase(createExpensesAccount.fulfilled, (state, { payload }) => {
      const { expensesAccountState } = payload;
      state.expensesAccount = expensesAccountState;
    });

    builder.addCase(
      updateExpensesAccountById.fulfilled,
      (state, { payload }) => {
        const { expensesAccountState } = payload;
        state.expensesAccount = expensesAccountState;
      }
    );

    builder.addCase(
      deleteExpensesAccountById.fulfilled,
      (state, { payload }) => {
        const { expensesAccountState } = payload;
        state.expensesAccount = expensesAccountState;
      }
    );

    //** expenses account trxns slice */
    builder.addCase(
      getAllExpensesAccountTrxns.fulfilled,
      (state, { payload }) => {
        state.expensesAccountTrxns = payload;
      }
    );

    builder.addCase(
      createExpensesAccountTrxn.fulfilled,
      (state, { payload }) => {
        const { expAccTrxnsState } = payload;
        state.expensesAccountTrxns = expAccTrxnsState;
      }
    );

    builder.addCase(
      updateExpensesAccountTrxnById.fulfilled,
      (state, { payload }) => {
        const { expAccTrxnsState } = payload;
        state.expensesAccountTrxns = expAccTrxnsState;
      }
    );

    builder.addCase(
      deleteExpensesAccountTrxnById.fulfilled,
      (state, { payload }) => {
        const { expAccTrxnsState } = payload;
        state.expensesAccountTrxns = expAccTrxnsState;
      }
    );
  },
});

export default expensesSlice.reducer;

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Monday 22 May 2023 17:17:04
*/
