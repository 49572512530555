/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Tuesday 26 July 2022 04:51:11
*/

import { lazy } from 'react';
import Loadable from 'app/components/Loadable/Loadable';

//** about routes components*/
//***************************************************************************/
//***************************************************************************/

const About = Loadable(lazy(() => import('./about/AboutOverview')));

//***************************************************************************/
//***************************************************************************/

const aboutRoutes = [
  //** about routes  */
  {
    path: '/about',
    element: <About />,
  },
];

export default aboutRoutes;

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Tuesday 26 July 2022 04:51:11
*/
