/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Wednesday 29 March 2023 00:01:26
*/

import { lazy } from 'react';
import Loadable from 'app/components/Loadable/Loadable';

//** sms-email routes components*/
//***************************************************************************/
//***************************************************************************/

const SendSms = Loadable(lazy(() => import('./send-sms/SendSms')));

const UsageAndBalance = Loadable(
  lazy(() => import('./send-sms/UsageAndBalanceOverview'))
);

//***************************************************************************/
//***************************************************************************/
const SendEmail = Loadable(lazy(() => import('./send-email/SendEmail')));
//***************************************************************************/
//***************************************************************************/

const smsEmailRoutes = [
  //** sms email routes  */
  {
    path: '/send-sms',
    element: <SendSms />,
  },
  {
    path: '/send-sms/usage-balance',
    element: <UsageAndBalance />,
  },
  {
    path: '/send-email',
    element: <SendEmail />,
  },
];

export default smsEmailRoutes;

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Wednesday 29 March 2023 02:58:16
*/
