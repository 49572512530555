/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Monday 20 December 2021 00:00:00
*/

import { authRoles } from 'app/auth/authRoles';

import { AiFillAppstore, AiOutlineTrophy, AiFillControl } from 'react-icons/ai';
import { BsFillPeopleFill } from 'react-icons/bs';
import {
  MdSpaceDashboard,
  MdLeaderboard,
  MdPeople,
  MdAddLocation,
  MdMarkEmailUnread,
} from 'react-icons/md';

import {
  FaUser,
  FaUserFriends,
  FaServer,
  FaPeopleArrows,
  FaMoneyBillWave,
  FaSms,
} from 'react-icons/fa';

import { RiMailSendLine, RiMoneyPoundCircleLine } from 'react-icons/ri';

import {
  SiSwiper,
  SiProducthunt,
  SiExpertsexchange,
  SiCoursera,
  SiInstapaper,
  SiCesium,
  SiRstudio,
} from 'react-icons/si';

export const navigations = [
  {
    name: 'Dashboard',
    path: '/dashboard',
    icon: <MdSpaceDashboard />,
  },
  {
    name: 'Staff Database',
    icon: <SiSwiper />,
    auth: authRoles.level4,
    children: [
      {
        name: 'Staff Profile',
        path: '/staff/database',
        icon: <MdPeople />,
      },
      {
        name: 'Former Staff',
        path: '/staff/former-staff',
        icon: <MdAddLocation />,
      },
    ],
  },
  {
    name: 'Netizens & Citizens',
    icon: <BsFillPeopleFill />,
    children: [
      {
        name: 'Participants Database',
        path: '/netizens/participants-database',
        icon: <FaPeopleArrows />,
      },
      {
        name: 'Experts Database',
        path: '/netizens/experts-database',
        icon: <SiExpertsexchange />,
      },
      {
        name: 'Prize Draw',
        path: '/netizens/prize-draw',
        icon: <AiOutlineTrophy />,
      },
      {
        name: 'Reward & Incentives',
        path: '/netizens/reward-incentives',
        icon: <FaMoneyBillWave />,
      },
      {
        name: 'Leaders Board',
        path: '/netizens/leaders-board',
        icon: <MdLeaderboard />,
      },
    ],
  },
  {
    name: 'Clients Database',
    icon: <SiCoursera />,
    path: '/clients/account',
    auth: authRoles.level3,
  },
  {
    name: 'Projects Database',
    path: '/projects',
    icon: <SiProducthunt />,
    auth: authRoles.level3,
  },
  {
    name: 'Project Panels',
    path: '/project-panels',
    icon: <AiFillControl />,
    auth: authRoles.level3,
  },
  {
    name: 'Cloud Storage',
    path: '/cloud-storage',
    icon: <FaServer />,
    auth: authRoles.level3,
  },
  {
    name: 'Sms & Email',
    icon: <RiMailSendLine />,
    children: [
      {
        name: 'Send SMS',
        path: '/send-sms',
        icon: <FaSms />,
      },
      {
        name: 'Send Email',
        path: '/send-email',
        icon: <MdMarkEmailUnread />,
      },
    ],
  },
  {
    name: 'Expenses Tracker',
    path: '/expenses-tracker',
    icon: <RiMoneyPoundCircleLine />,
    auth: authRoles.level4,
  },
  {
    name: 'Manage Users',
    path: '/manage-users/users',
    icon: <FaUserFriends />,
    auth: authRoles.level4,
  },
  {
    name: 'My Account',
    path: '/my-account',
    icon: <FaUser />,
  },
  {
    name: 'In-House Resources',
    icon: <SiInstapaper />,
    children: [
      {
        name: 'Training & Development',
        path: '/resources/training-resources',
        icon: <SiCesium />,
      },
      {
        name: 'Research Resources',
        path: '/resources/research-resources',
        icon: <SiRstudio />,
      },
    ],
  },
  {
    name: 'About',
    path: '/about',
    icon: <AiFillAppstore />,
  },
];

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Sunday 23 April 2023 20:20:11
*/
