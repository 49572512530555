/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Wednesday 10 August 2022 05:03:25
*/

import http from '../http';

//** get all backup history */
export const GetAllBackupHistory = async () => {
  try {
    const res = await http.get('/database-backup-history');
    return res.data.data;
  } catch (err) {}
};

//** get backup history by id */
export const GetBackupHistoryById = async (id) => {
  try {
    const res = await http.get(`/database-backup-history/${id}`);
    return res.data.data;
  } catch (err) {}
};

//** create backup history */
export const CreateBackupHistory = async (data) => {
  try {
    const res = await http.post('/database-backup-history', data);
    return res.data.data;
  } catch (err) {
    return err;
  }
};

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Wednesday 07 September 2022 13:12:20
*/
