/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Friday 08 July 2022 07:10:00
*/

import { createAsyncThunk } from '@reduxjs/toolkit';

//** import admin services */
import {
  GetAllAdmin,
  CreateAdmin,
  UpdateAdminById,
  DeleteAdminById,
  GetAuthSecretKey,
} from '../../services/admin/Admin.services';

import store from 'app/store/store';

//***************************************************************************/
//***************************************************************************/

//**  admin thunk starts  */
export const getAllAdmin = createAsyncThunk('get-all-admin', async () => {
  const res = await GetAllAdmin();
  return res;
});

export const createAdmin = createAsyncThunk(
  'create-admin',
  async (data, { getState }) => {
    const res = await CreateAdmin(data);
    const { admin } = getState().admin;
    const adminState = [...admin, res];
    return { adminState };
  }
);

export const updateAdminById = createAsyncThunk(
  'update-admin-byId',
  async ({ adminId, ...rest }) => {
    const res = await UpdateAdminById(adminId, rest);
    const { admin } = store.getState().admin;
    const filteredAdmin = admin.filter((t) => t.adminId !== adminId);
    const adminState = [...filteredAdmin, res];
    return { adminState };
  }
);

export const deleteAdminById = createAsyncThunk(
  'delete-admin-byId',
  async (adminId) => {
    await DeleteAdminById(adminId);
    const { admin } = store.getState().admin;
    const filteredAdmin = admin.filter((t) => t.adminId !== adminId);
    const adminState = [...filteredAdmin];
    return { adminState };
  }
);

export const getAuthSecretKey = createAsyncThunk(
  'get-auth-secret-key',
  async () => {
    const res = await GetAuthSecretKey();
    return res;
  }
);

//** admin thunk ends  */

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Tuesday 14 March 2023 16:56:57
*/
