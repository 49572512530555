/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Friday 08 July 2022 07:22:31
*/

import { createSlice } from '@reduxjs/toolkit';

import {
  getAllAdmin,
  createAdmin,
  updateAdminById,
  deleteAdminById,
} from '../thunk/admin.Thunk';

const initialState = {
  admin: [],
};

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getAllAdmin.fulfilled, (state, { payload }) => {
      state.admin = payload;
    });

    builder.addCase(createAdmin.fulfilled, (state, { payload }) => {
      const { adminState } = payload;
      state.admin = adminState;
    });

    builder.addCase(updateAdminById.fulfilled, (state, { payload }) => {
      const { adminState } = payload;
      state.admin = adminState;
    });

    builder.addCase(deleteAdminById.fulfilled, (state, { payload }) => {
      const { adminState } = payload;
      state.admin = adminState;
    });
  },
});

export default adminSlice.reducer;

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Monday 22 May 2023 17:06:18
*/
