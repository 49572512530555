/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Monday 20 December 2021 00:00:00
*/

import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  GetAllStaffProfile,
  CreateStaffProfile,
  UpdateStaffProfileById,
  DeleteStaffProfileById,
} from 'app/services/staff/StaffProfile.services';

import store from 'app/store/store';

export const getAllStaffProfile = createAsyncThunk(
  'staff-profile/getAll',
  async () => {
    const res = await GetAllStaffProfile();
    return res;
  }
);

export const createStaffProfile = createAsyncThunk(
  'staff-profile/create',
  async (data, { getState }) => {
    const res = await CreateStaffProfile(data);
    const { staffProfile } = getState().staffProfile;
    const staffProfileState = [...staffProfile, res];
    return { staffProfileState };
  }
);

export const updateStaffProfileById = createAsyncThunk(
  'staff-profile/update',
  async ({ employeeId, ...data }) => {
    const res = await UpdateStaffProfileById(employeeId, data);
    const { staffProfile } = store.getState().staffProfile;    
    const filteredStaffProfile = staffProfile?.filter(
      (t) => t.employeeId !== employeeId
    );
    const staffProfileState = [res, ...filteredStaffProfile];
    return { staffProfileState };
  }
);

export const deleteStaffProfileById = createAsyncThunk(
  'staff-profile/delete',
  async (employeeId) => {
    await DeleteStaffProfileById(employeeId);
    const { staffProfile } = store.getState().staffProfile;
    const staffProfileState = staffProfile?.filter(
      (t) => t.employeeId !== employeeId
    );
    return { staffProfileState };
  }
);

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Wednesday 15 March 2023 16:07:43
*/
