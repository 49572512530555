/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Monday 27 February 2023 12:24:54
*/

import { createAsyncThunk } from '@reduxjs/toolkit';

//** get file storage */
import {
  GetAllFileStorageHistory,
  GetFileStorageHistoryById,
  CreateFileStorageHistory,
  DeleteFileStorageHistoryById,
} from 'app/services/file-storage-history/FileStorageHistory.services';

import store from 'app/store/store';

//***************************************************************************/
//***************************************************************************/

//** file storage history thunk starts  */
export const getAllFileStorageHistory = createAsyncThunk(
  'get-all-file-storage-history',
  async () => {
    const res = await GetAllFileStorageHistory();
    return res;
  }
);

export const getFileStorageHistoryById = createAsyncThunk(
  'get-file-storage-history-byId',
  async (id) => {
    const res = await GetFileStorageHistoryById(id);
    return res;
  }
);

export const createFileStorageHistory = createAsyncThunk(
  'create-file-storage-history',
  async (data, { getState }) => {
    const res = await CreateFileStorageHistory(data);
    const { fileStorageHistory } = getState().fileStorageHistory;
    const fileStorageHistoryState = [...fileStorageHistory, res];
    return { fileStorageHistoryState };
  }
);

export const deleteFileStorageHistoryById = createAsyncThunk(
  'delete-file-storage-history-byId',
  async (fileId) => {
    await DeleteFileStorageHistoryById(fileId);

    const { fileStorageHistory } = store.getState().fileStorageHistory;
    const fileStorageHistoryState = fileStorageHistory?.filter(
      (t) => t.fileId !== fileId
    );

    return { fileStorageHistoryState };
  }
);
//** file storage history thunk ends  */

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Wednesday 15 March 2023 12:24:49
*/
