/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Tuesday 21 December 2021 09:00:00
*/

import { lazy } from 'react';

import Loadable from 'app/components/Loadable/Loadable';

const StaffProfileOverview = Loadable(
  lazy(() => import('./staff-profile/StaffProfile'))
);

const StaffProfileList = Loadable(
  lazy(() => import('./staff-profile/StaffProfileList'))
);

const StaffProfileDetailsViewer = Loadable(
  lazy(() => import('./staff-profile/staff-details/StaffDetailsOverview'))
);

const StaffProfileFormOverview = Loadable(
  lazy(() =>
    import('./staff-profile/create-staff-profile/StaffProfileFormOverview')
  )
);

const SuccessPage = Loadable(
  lazy(() => import('./staff-profile/create-staff-profile/SuccessPage'))
);

//*****************************************************************/
//*****************************************************************/

const FormerStaffOverview = Loadable(
  lazy(() => import('./former-staff/FormerStaffOverview'))
);

const FormerStaffDetailsList = Loadable(
  lazy(() => import('./former-staff/FormerStaffDetailsList'))
);

const FormerStaffDetailsViewer = Loadable(
  lazy(() => import('./former-staff/FormerStaffDetailsViewer'))
);

//*****************************************************************/
//*****************************************************************/

const staffDatabaseRoutes = [
  {
    path: 'staff/database',
    element: <StaffProfileOverview />,
  },
  {
    path: 'staff/staff-profile-list',
    element: <StaffProfileList />,
  },
  {
    path: 'staff/profile/:id',
    element: <StaffProfileDetailsViewer />,
  },
  {
    path: '/staff/create-profile',
    element: <StaffProfileFormOverview />,
  },
  {
    path: '/staff/success-page',
    element: <SuccessPage />,
  },

  {
    path: 'staff/former-staff',
    element: <FormerStaffOverview />,
  },
  {
    path: 'staff/former-staff-details-list',
    element: <FormerStaffDetailsList />,
  },
  {
    path: 'staff/former-staff/:id',
    element: <FormerStaffDetailsViewer />,
  },
];

export default staffDatabaseRoutes;

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Thursday 13 October 2022 00:37:03
*/