/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Wednesday 16 November 2022 05:21:42
*/

import http from '../http';

//** get all clients rep http request */
export const GetAllClientsRep = async () => {
  try {
    const res = await http.get('/clients-rep');
    return res.data.data;
  } catch (err) {
    return err;
  }
};

//** get client rep by id http request */
export const GetClientRepById = async (id) => {
  try {
    const res = await http.get(`/clients-rep/${id}`);
    return res.data.data;
  } catch (err) {
    return err;
  }
};

//** create client rep http request */
export const CreateClientRep = async (data) => {
  try {
    const res = await http.post('/clients-rep/', data);
    return res.data.data;
  } catch (err) {
    return err;
  }
};

//** update client rep by id http request */
export const UpdateClientRepById = async (id, data) => {
  const res = await http.patch(`/clients-rep/${id}`, data);
  return res.data.data;
};

//** delete client rep http request */
export const DeleteClientRepById = async (id) => {
  const res = await http.delete(`/clients-rep/${id}`);
  return res.data;
};

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Tuesday 14 March 2023 18:50:18
*/
