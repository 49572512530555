/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Friday 27 January 2023 03:23:07
*/

import http from '../http';

//** get all sub panel participants http request */
export const GetAllSubPanelParticipants = async () => {
  try {
    const res = await http.get('/sub-panel-participants');
    return res.data.data;
  } catch (err) {}
};

//** get sub panel participant by id http request */
export const GetSubPanelParticipantById = async (id) => {
  try {
    const res = await http.get(`/sub-panel-participants/${id}`);
    return res.data.data;
  } catch (err) {
    return err;
  }
};

//** create sub panel participant http request */
export const CreateSubPanelParticipant = async (data) => {
  try {
    const res = await http.post('/sub-panel-participants-many', data);
    return res.data.data;
  } catch (err) {
    return err;
  }
};

//** update sub panel participant by id http request */
export const UpdateSubPanelParticipantById = async (id, data) => {
  const res = await http.patch(`/sub-panel-participants/${id}`, data);
  return res.data.data;
};

//** deletesub panel participant http request */
export const DeleteSubPanelParticipantById = async (id) => {
  const res = await http.delete(`/sub-panel-participants/${id}`);
  return res.data;
};

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Friday 27 January 2023 04:33:31
*/
