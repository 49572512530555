/*
//** Code Signature
//** Name: Quimby africana
//** Created on: Tuesday 20 September 2022 12:11:02
*/

import { createAsyncThunk } from '@reduxjs/toolkit';

//** import invoice history services */
import {
  GetAllInvoiceHistory,
  CreateInvoiceHistory,
  UpdateInvoiceHistoryById,
  DeleteInvoiceHistoryById,
} from '../../services/invoice-history/InvoiceHistory.services';

import store from 'app/store/store';

//***************************************************************************/
//***************************************************************************/
//** invoice history thunk starts  */

export const getAllInvoiceHistory = createAsyncThunk(
  'get-all-invoice-history',
  async () => {
    const res = await GetAllInvoiceHistory();
    return res;
  }
);

export const createInvoiceHistory = createAsyncThunk(
  'create-invoice-history',
  async (data, { getState }) => {
    const res = await CreateInvoiceHistory(data);
    const { invoiceHistory } = getState().invoiceHistory;
    const invoiceHistoryState = [...invoiceHistory, res];
    return { invoiceHistoryState };
  }
);

export const updateInvoiceHistoryById = createAsyncThunk(
  'update-invoice-history-byId',
  async ({ invoiceId, ...rest }) => {
    const res = await UpdateInvoiceHistoryById(invoiceId, rest);
    const { invoiceHistory } = store.getState().invoiceHistory;

    const filteredInvoiceHistory = invoiceHistory?.filter(
      (t) => t.invoiceId !== invoiceId
    );
    const invoiceHistoryState = [...filteredInvoiceHistory, res];
    return { invoiceHistoryState };
  }
);

export const deleteInvoiceHistoryById = createAsyncThunk(
  'delete-invoice-history-byId',
  async (invoiceId) => {
    await DeleteInvoiceHistoryById(invoiceId);
    const { invoiceHistory } = store.getState().invoiceHistory;

    const filteredInvoiceHistory = invoiceHistory?.filter(
      (t) => t.invoiceId !== invoiceId
    );
    const invoiceHistoryState = [...filteredInvoiceHistory];
    return { invoiceHistoryState };
  }
);

//** invoice history thunk ends  */

/*
//** Code Signature
//** Name: Quimby africana
//** Modified on: Wednesday 15 March 2023 12:32:54
*/
